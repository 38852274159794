import React, {useState} from "react";
import { HashRouter, Route, Switch, useHistory } from "react-router-dom";
import '@nosferatu500/react-sortable-tree/style.css';
import "./scss/style.scss";
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { lazyWithRetry } from "./utils/lazyWithRetry";
import { isIE } from 'react-device-detect'

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";
import TabProvider from "./context/TabContext";
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup
} from "@material-ui/core";
import {useIntl} from "react-intl";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

type AppProps = {
  pca: IPublicClientApplication;
};

// Containers
const TheLayout = lazyWithRetry(() => import("./containers/TheLayout"));

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTSID,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

function App({ pca }: AppProps) {
  const history = useHistory();
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(isIE && localStorage.getItem('ie-notification-hide') !== 'true');
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    localStorage.setItem('ie-notification-hide', event.target.checked);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'global.browser.incompatible.title' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({ id: 'global.browser.incompatible.message' })}
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleChange} />}
              label={intl.formatMessage({ id: 'global.browser.incompatible.hide' })}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {intl.formatMessage({ id: 'global.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
      {!open &&
        <MsalProvider instance={pca}>
          <AppInsightsErrorBoundary onError={() => <h1>{intl.formatMessage({ id: 'global.something.went.wrong' })}</h1>} appInsights={reactPlugin}>
            <TabProvider>
              <HashRouter>
                <ThemeProvider theme={theme}>
                  <CssBaseline/>
                  <React.Suspense fallback={loading}>
                    <Switch>
                      <Route path="/" component={TheLayout}/>
                    </Switch>
                  </React.Suspense>
                </ThemeProvider>
              </HashRouter>
            </TabProvider>
          </AppInsightsErrorBoundary>
        </MsalProvider>
      }
    </>
  );
}

export default App;
