import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { INotification } from "domain/notifications/notification"

type NotificationReducerPayload =
| INotification[]
| null;

export interface INotificationAction
  extends IDispatchBaseActionResult<NotificationReducerPayload, any> {}

export interface INotificationState extends IAuditable {
  loading: boolean;
  notifications: IAuditableResult<INotification[], any> | null;
  todayCount: number;
  error: any | null;
}

const initialState: INotificationState = {
  loading: false,
  notifications: new AuditableResult<INotification[], any>(),
  todayCount: 0,
  error: null,
};

const notificationReducer = (
  state = initialState,
  action: INotificationAction
): INotificationState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_MY_NOTIFICATIONS):
      return {
        ...state,
        notifications: {
          loading: true,
          data: action.loadMore ? state.notifications!.data : null,
        },
      };
    case ActionModifier.request(types.GET_MY_NOTIFICATIONS_TODAY_COUNT):
      return {
        ...state,
        todayCount: 0
      };

    // SUCCESS
    case ActionModifier.success(types.GET_MY_NOTIFICATIONS):
      return {
        ...state,
        notifications: {
          loading: false,
          data: action.loadMore ? [...state.notifications!.data, ...(action.payload as INotification[])] : action.payload as INotification[],
        },
      };
    case ActionModifier.success(types.GET_MY_NOTIFICATIONS_TODAY_COUNT):
      return {
        ...state,
        todayCount: (action.payload as INotification[]).length
      };

    // FAILURE
    case ActionModifier.failure(types.GET_MY_NOTIFICATIONS):
      return {
        ...state,
        notifications: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_MY_NOTIFICATIONS_TODAY_COUNT):
      return {
        ...state,
        todayCount: 0,
        error: action.payload as any,
      };

    //DISPOSE
    case ActionModifier.dispose(types.GET_MY_NOTIFICATIONS):
      return {
        ...state,
        notifications: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default notificationReducer;
