export class ActionModifier {
  public static request(type: string) {
    return `${type?.toUpperCase()}_REQUEST`;
  }

  public static success(type: string) {
    return `${type?.toUpperCase()}_SUCCESS`;
  }

  public static failure(type: string) {
    return `${type?.toUpperCase()}_FAILURE`;
  }

  public static dispose(type: string) {
    return `${type?.toUpperCase()}_DISPOSE`;
  }
}
