import * as CommonInterfaces from "./interface";
import {IId, ISite, IUserMinimal} from "./interface";

export enum ItemDataValue {
  ACTIVE = "ACT",
  IN_STOCK = "IST",
  INACTIVE = "INA",
  DRAFT = "DFT",
  OPEN = "OPN",
  REVIEW = "RVW",
  CLOSE = "CLS",
  CANCEL = "CAN",
  PICTURE = "PIC",
  ATTACHMENT = "ATT",
  TASK_COMPLETED = "CMP",
  NOT_STARTED = "NST",
  IN_PROGRESS = "IPR",
  REPORT = "REP",
  REGISTRATION = "REG",
  APPROVED = "APR",
  ASK_FOR_REVIEW = "AFR",
  REJECT = "REJ",
  CONCLUSION = "CON",
  NEW = "NEW",
  PUBLISHED = "PBL",
  FINISHED = "FIN"
}

export enum Entity {
  AccountWithTeams = "AccountWithTeams",
  Complaint = "Complaint",
  Document = "Document",
  NonConformity = "NonConformity",
  SafetyIncident = "SafetyIncident",
  CAPA = "CAPA",
  Electricity = "Electricity",
  Waste = "Waste",
  Documents = "Documents",
  Attachments = "Attachments",
  RelatedItems = "RelatedItems",
  ProductReturns = "ProductReturns",
  Task = "Task",
  Audit = "Audit",
  AuditTemplate = "AuditTemplate",
  AuditTemplateQuestions = "AuditTemplateQuestion",
  Group = "Group",
  User = "User",
  Notification = "Notification",
  Equipment = "Equipment",
  EquipmentControl = "EquipmentControl",
  ReferenceEquipment = "ReferenceEquipment",
  Translations = "Translations",
  Sites = "Sites",
  Workflow = "Workflow",
  Draft = "Draft"
}

export enum TrackingColor {
  Good,
  Warning,
  Critical
}
export class Id implements CommonInterfaces.IId {
  id: string;
}

export class ItemData extends Id implements CommonInterfaces.IItemData {
  id: string;
  label: string;
  value: ItemDataValue;
  colorHex: string;
  itemDataType: string;
  translations?: Translation[];
  children?: ItemData[];
  order?: number;
  isActive?: boolean;
  fid?: string; // added by the frontend
}

export interface Translation {
  locale: string;
  label: string;
}

export interface IItemDataHierarchical {
  children?: IItemDataHierarchicalChild[];
  created: Date;
  depth: number;
  eTag: Guid;
  id: Guid;
  isActive: boolean;
  itemDataType: string;
  label: string;
  order: number;
  value: string;
}

interface IItemDataHierarchicalChild extends IId {
  label: string;
  value: ItemDataValue;
  colorHex: string;
  itemDataType: string;
  id: string;
  children: IItemDataHierarchicalChild[];
}

export interface IItemDataHierarchicalMinimal {
  id: Guid;
  itemDataType: string;
  label: string;
  value: string;
  children: IItemDataHierarchicalChildMinimal[];
}

export interface IEquipmentControlMinimal {
  id: Guid;
  title: string;
}

export interface IMoney {
  amount: number;
  currency: string;
}

export interface IItemDataHierarchicalChildMinimal {
  id: Guid;
  isActive: boolean;
  itemDataType: string;
  label: string;
  parentId: Guid;
  value: string;
  children: IItemDataHierarchicalChildMinimal[];
}

export interface IFieldDefinition {
  id: Guid;
  eTag: string;
  tenantId: Guid;
  version: number;
  createdBy: IUserMinimal;
  created: Date;
  sites: ISite[];
  name: string;
  roles: any[];
  groupUsers: IUserMinimal[];
}

export interface ACL {
  canRead: boolean;
  canDelete: boolean;
  canUpdate: boolean;
  canSubmit: boolean;
}

export enum SearchTypes {
  All = 'all',
  Customer = 'cus',
  Supplier = 'sup'
}

export interface IGlobalSearchResult {
  number: string;
  description: string;
  entityName: string;
  sites: ISite[];
  status: ItemData;
  id: Guid;
  created: Date;
  modified: Date;
  highlights: IGlobalSearchResultHighlight;
}

export interface IGlobalSearchResultHighlight {
  content: string[];
  name: string[];
  title: string[];
  description: string[];
}

export interface ICustomerLogo {
  logo: string;
}

export interface ITenantSettings {
  globalSearchEnabled: boolean;
}

export interface EnumData {
  label: string;
  value: string;
}
