import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import { Complaint } from "../../domain/complaints/Complaint";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { IProductReturns } from "domain/product-returns/ProductReturn";
import { IAttachments } from "domain/attachments/Attachment";
import { ComplaintMetadata } from "../../domain/complaints/ComplaintMetadata";
import { IAttachmentToken } from "domain/attachments/IAttachmentToken";
import {IComplaintEvent} from "../../domain/complaint-events/ComplaintEvent";
import {IWorkflowInstance} from "../../domain/workflow-instances/IWorkflowInstance";
import {IWorkflowTaskExtended} from "../../domain/workflow-instances/IWorkflowTaskExtended";

type ComplaintReducerPayload =
  | Complaint
  | Complaint[]
  | ComplaintMetadata
  | Guid
  | Guid[]
  | IProductReturns[]
  | IAttachments
  | IAttachmentToken
  | IAttachments[]
  | IWorkflowInstance[]
  | IComplaintEvent[]
  | IWorkflowTaskExtended
  | null;

export interface IComplaintAction
  extends IDispatchBaseActionResult<ComplaintReducerPayload, any> {}

export interface IComplaintState extends IAuditable {
  loading: boolean;
  refreshCapas: boolean;
  complaintDetails: IAuditableResult<Complaint, any> | null;
  complaints: IAuditableResult<Complaint[], any> | null;
  searchResults: IAuditableResult<Complaint[], any> | null;
  complaintsMetadata: IAuditableResult<ComplaintMetadata, any> | null;
  productReturns: IAuditableResult<IProductReturns[], any> | null;
  attachments: IAuditableResult<IAttachments[], any> | null;
  attachmentToken: IAuditableResult<IAttachmentToken, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  workflows: IAuditableResult<IWorkflowInstance[], any> | null;
  error: any | null;
}

const initialState: IComplaintState = {
  loading: false,
  refreshCapas: false,
  complaintDetails: new AuditableResult<Complaint, any>(),
  complaints: new AuditableResult<Complaint[], any>(),
  searchResults: new AuditableResult<Complaint[], any>(),
  complaintsMetadata: new AuditableResult<ComplaintMetadata, any>(),
  productReturns: new AuditableResult<IProductReturns[], any>(),
  attachments: new AuditableResult<IAttachments[], any>(),
  attachmentToken: new AuditableResult<IAttachmentToken, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  workflows: new AuditableResult<IWorkflowInstance[], any>(),
  error: null,
};

const complaintReducer = (
  state = initialState,
  action: IComplaintAction
): IComplaintState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_COMPLAINTS):
      return {
        ...state,
        complaints: {
          loading: true,
          data: action.loadMore ? state.complaints!.data : null,
        },
      };
    case ActionModifier.request(types.SEARCH_COMPLAINTS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINTS_METADATA):
      return {
        ...state,
        complaintsMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: true,
      };
    case ActionModifier.request(types.SAVE_COMPLAINT):
    case ActionModifier.request(types.UPDATE_COMPLAINT):
    case ActionModifier.request(types.PATCH_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          saving: true,
          data: (state.complaintDetails
            ? state.complaintDetails.data
            : null) as Complaint,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.ADD_COMPLAINT_PRODUCTS_RETURNS):
    case ActionModifier.request(types.UPDATE_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          loading: true,
          data: (state.productReturns ? state.productReturns.data : null) as IProductReturns[],
        },
      };

    case ActionModifier.request(types.SAVE_COMPLAINT_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_COMPLAINT_ATTACHMENT):
    case ActionModifier.request(types.DELETE_COMPLAINT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: true,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMPLAINT_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.DELETE_COMPLAINTS):
      return {
        ...state,
        complaints: {
          loading: true,
          data: (state.complaints ? state.complaints.data : null) as Complaint[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_COMPLAINTS):
      return {
        ...state,
        complaints: {
          loading: false,
          data: action.loadMore ? [...state.complaints!.data, ...(action.payload as Complaint[])] : action.payload as Complaint[],
        },
      };
    case ActionModifier.success(types.SEARCH_COMPLAINTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as Complaint[],
        },
      };
    case ActionModifier.success(types.GET_COMPLAINTS_METADATA):
      return {
        ...state,
        complaintsMetadata: {
          loading: false,
          data: action.payload as ComplaintMetadata,
        },
      };

    case ActionModifier.success(types.GET_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          loading: false,
          data: action.payload as Complaint,
        },
      };
    case ActionModifier.success(types.GET_COMPLAINT_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: false,
        complaintDetails: {
          loading: false,
          data: action.payload as Complaint,
        },
      };

    case ActionModifier.success(types.SAVE_COMPLAINT):
    case ActionModifier.success(types.UPDATE_COMPLAINT):
    case ActionModifier.success(types.PATCH_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          saving: false,
          data: (state.complaintDetails
            ? state.complaintDetails.data
            : null) as Complaint,
        },
      };

    case ActionModifier.success(types.DELETE_COMPLAINTS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.complaints?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        complaints: {
          loading: false,
          data: newData as Complaint[],
        },
      };
    case ActionModifier.success(types.GET_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          loading: false,
          data: action.payload as IProductReturns[],
        },
      };
    case ActionModifier.success(types.UPDATE_COMPLAINT_PRODUCTS_RETURNS):
    case ActionModifier.success(types.ADD_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          loading: true,
          data: (state.productReturns
            ? state.productReturns.data
            : null) as IProductReturns[],
        },
      };
    case ActionModifier.success(types.DELETE_COMPLAINT_PRODUCTS_RETURN):
      const id: Guid = action.payload as any;
      return {
        ...state,
        productReturns: {
          loading: false,
          data: state.productReturns?.data?.filter(
            (e) => e.id !== id
          ) as IProductReturns[],
        },
      };
    case ActionModifier.success(types.GET_COMPLAINT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          loading: false,
          data: action.payload as IAttachments[],
        },
      };
    case ActionModifier.success(types.GET_COMPLAINT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (complaintEvent1, complaintEvent2) =>
              complaintEvent2.eventTime.localeCompare(complaintEvent1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_COMPLAINT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: action.payload as IWorkflowInstance[],
        },
      };
    case ActionModifier.success(types.GET_COMPLAINT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: false,
          data: action.payload as IAttachmentToken,
        },
      };
    case ActionModifier.success(types.DELETE_COMPLAINT_ATTACHMENT):
      const idComplaintAttachment: Guid = action.payload as any;
      return {
        ...state,
        loading: false,
        attachments: {
          loading: false,
          data: state.attachments?.data?.filter((e) => e.id !== idComplaintAttachment) as IAttachments[]
        },
      };
    case ActionModifier.success(types.SAVE_COMPLAINT_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_COMPLAINT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_COMPLAINTS):
      return {
        ...state,
        complaints: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_COMPLAINTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMPLAINTS_METADATA):
      return {
        ...state,
        complaintsMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMPLAINT_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: false,
        complaintDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_COMPLAINT):
    case ActionModifier.failure(types.UPDATE_COMPLAINT):
    case ActionModifier.failure(types.PATCH_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          saving: false,
          data: (state.complaintDetails
            ? state.complaintDetails.data
            : null) as Complaint,
        },
      };
    case ActionModifier.failure(types.DELETE_COMPLAINTS):
      return {
        ...state,
        complaints: {
          loading: false,
          data: (state.complaints ? state.complaints.data : null) as Complaint[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.ADD_COMPLAINT_PRODUCTS_RETURNS):
    case ActionModifier.failure(types.UPDATE_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          loading: true,
          data: []
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_COMPLAINT_PRODUCTS_RETURN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_COMPLAINT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMPLAINT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMPLAINT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMPLAINT_ATTACHMENT_TOKEN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_COMPLAINT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_COMPLAINT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_COMPLAINT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_COMPLAINT):
      return {
        ...state,
        complaintDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_COMPLAINT_PRODUCTS_RETURNS):
      return {
        ...state,
        productReturns: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_COMPLAINT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_COMPLAINT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default complaintReducer;
