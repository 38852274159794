import { combineReducers } from "redux";
import { dashboardReducer } from "./dashboardReducer";
import complaintReducer, { IComplaintState } from "./complaintReducer";
import nonConformityReducer, { INonConformityState } from "./nonConformityReducer";
import safetyIncidentReducer, { ISafetyIncidentState } from "./safetyIncidentReducer";
import capaReducer, { ICapaState } from "./capaReducer";
import electricityReducer, { IElectricityState } from "./electricityReducer";
import wasteReducer, { IWasteState } from "./wasteReducer";
import documentReducer, { IDocumentState } from "./documentReducer";
import auditReducer, { IAuditState } from "./auditReducer";
import commonReducer, { ICommonState } from "./commonReducer";
import userReducer, { IUserState } from "./userReducer";
import notificationReducer, { INotificationState } from "./notificationReducer";
import taskReducer, { ITaskState } from "./taskReducer";
import toasterReducer, { IToasterState } from "./toasterReducer";
import groupReducer, { IGroupState } from "./groupReducer";
import siteReducer, { ISiteState } from "./siteReducer";
import accountWithTeamsReducer, { IAccountWithTeamsState } from "./accountWithTeamsReducer";
import equipmentReducer , { IEquipmentState } from "./equipmentReducer";
import managementReducer , { IManagementState } from "./managementReducer";

export type RootReducer = {
  readonly dashboard: any;
  readonly complaints: IComplaintState;
  readonly nonConformities: INonConformityState;
  readonly safetyIncidents: ISafetyIncidentState;
  readonly capas: ICapaState;
  readonly electricity: IElectricityState;
  readonly waste: IWasteState;
  readonly documents: IDocumentState;
  readonly audits: IAuditState;
  readonly common: ICommonState;
  readonly users: IUserState;
  readonly notifications: INotificationState;
  readonly tasks: ITaskState;
  readonly toaster: IToasterState;
  readonly groups: IGroupState;
  readonly sites: ISiteState;
  readonly accountsWithTeams: IAccountWithTeamsState;
  readonly equipments: IEquipmentState;
  readonly management: IManagementState;
};

export const rootReducer = combineReducers<RootReducer>({
  dashboard: dashboardReducer,
  complaints: complaintReducer,
  nonConformities: nonConformityReducer,
  safetyIncidents: safetyIncidentReducer,
  capas: capaReducer,
  electricity: electricityReducer,
  waste: wasteReducer,
  documents: documentReducer,
  audits: auditReducer,
  common: commonReducer,
  users: userReducer,
  notifications: notificationReducer,
  tasks: taskReducer,
  toaster: toasterReducer,
  groups: groupReducer,
  sites: siteReducer,
  accountsWithTeams: accountWithTeamsReducer,
  equipments: equipmentReducer,
  management: managementReducer
});

export type RootState = ReturnType<typeof rootReducer>;
