import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {ItemDataType} from "../../domain/management/ItemDataType";

type ManagementReducerPayload =
  | ItemDataType[]
  | null;

export interface IManagementAction
  extends IDispatchBaseActionResult<ManagementReducerPayload, any> {}

export interface IManagementState extends IAuditable {
  loading: boolean;
  itemDataTypes: IAuditableResult<ItemDataType[], any> | null;
  error: any | null;
}

const initialState: IManagementState = {
  loading: false,
  itemDataTypes: new AuditableResult<ItemDataType[], any>(),
  error: null,
};

const managementReducer = (
  state = initialState,
  action: IManagementAction
): IManagementState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_ITEM_DATAS):
      return {
        ...state,
        loading: true,
        itemDataTypes: {
          data: (state.itemDataTypes ? state.itemDataTypes.data : []) as ItemDataType[],
        },
      };
    case ActionModifier.request(types.UPDATE_ITEM_DATAS):
      return {
        ...state,
        loading: true,
        itemDataTypes: {
          data: (state.itemDataTypes ? state.itemDataTypes.data : []) as ItemDataType[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_ITEM_DATAS):
      return {
        ...state,
        loading: false,
        itemDataTypes: {
          data: (action.payload as ItemDataType[])
            .sort((item1, item2) => item1.itemDataType.localeCompare(item2.itemDataType)),
        },
      };
    case ActionModifier.success(types.UPDATE_ITEM_DATAS):
      return {
        ...state,
        loading: false,
        itemDataTypes: {
          data: (action.payload as ItemDataType[])
            .sort((item1, item2) => item1.itemDataType.localeCompare(item2.itemDataType))
            .map(itemDataType => {
              const values = itemDataType.values.map(value => {
                const translations = value.translations;
                if (!translations!.find(translation => translation.locale === 'nl')) {
                  translations!.push({ locale: 'nl', label: ''});
                }
                if (!translations!.find(translation => translation.locale === 'fr')) {
                  translations!.push({ locale: 'fr', label: ''});
                }
                return {
                  ...value,
                  fid: value.id,
                  translations
                }
              });
              return {
                ...itemDataType,
                values
              }
            }),
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_ITEM_DATAS):
      return {
        ...state,
        loading: false,
        itemDataTypes: {
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.UPDATE_ITEM_DATAS):
      return {
        ...state,
        loading: false,
        itemDataTypes: {
          data: (state.itemDataTypes ? state.itemDataTypes.data : []) as ItemDataType[],
        },
      };
    default:
      return state;
  }
};

export default managementReducer;
