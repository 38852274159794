const config: {
  backend_api_app: string;
  sso_clientid: string;
  sso_authority: string;
  sso_redirecturi: string;
  sso_postlogoutredirecturi: string;
  sso_scopes: string[];  
} = {
  backend_api_app: process.env.REACT_APP_BACKEND_API_APP || "",
  sso_clientid: process.env.REACT_APP_SSO_CLIENTID || "",
  sso_authority: process.env.REACT_APP_SSO_AUTHORITY || "",
  sso_redirecturi: process.env.REACT_APP_SSO_REDIRECTURI || "",
  sso_postlogoutredirecturi: process.env.REACT_APP_SSO_POSTLOGOUTREDIRECTURI || "",
  sso_scopes: (process.env.REACT_APP_SSO_SCOPES && process.env.REACT_APP_SSO_SCOPES.split(',')) || [""]
}

export default config;