import React from 'react';
import {ITab, TabContextType} from "./type";

export const TabContext = React.createContext<TabContextType | null>(null);

const TabProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [tabs, setTabs] = React.useState<ITab[]>([
        {
            pageName: "complaints",
            tab: "opened"
        },
        {
            pageName: "non-conformities",
            tab: "opened"
        },
        {
            pageName: "safety-incidents",
            tab: "accident"
        },
        {
            pageName: "capas",
            tab: "opened"
        },
        {
            pageName: "audits",
            tab: "new"
        },
        {
            pageName: "auditTemplates",
            tab: "all"
        },
        {
            pageName: "questionTemplates",
            tab: "all"
        },
        {
            pageName: "notifications",
            tab: "all"
        },
        {
            pageName: "tasks",
            tab: "overdue"
        },
        {
            pageName: "electricity",
            tab: "all"
        },
        {
            pageName: "waste",
            tab: "all"
        },
        {
            pageName: "documents",
            tab: "published"
        },
        {
            pageName: "config",
            tab: "groups"
        },
        {
            pageName: "search",
            tab: "all"
        },
        {
            pageName: "equipments",
            tab: "active"
        },
        {
            pageName: "reference-equipments",
            tab: "reference"
        },
    ]);

    const updateTab = (pageName: string, tabName: string) => {
        tabs.forEach((tab: ITab) => {
            if (tab.pageName === pageName) {
                tab.tab = tabName
                setTabs([...tabs])
            }
        })
    }

    return (
        <TabContext.Provider value={{ tabs, updateTab }}>
            {children}
        </TabContext.Provider>
    );
}

export default TabProvider
