import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import { ICapa } from "../../domain/capas/Capa";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { IAttachments } from "domain/attachments/Attachment";
import { IAttachmentToken } from "domain/attachments/IAttachmentToken";
import { IComplaintEvent } from "../../domain/complaint-events/ComplaintEvent";
import { CapaMetadata } from "domain/capas/CapaMetadata";
import {IWorkflowInstance} from "../../domain/workflow-instances/IWorkflowInstance";

type CapaReducerPayload =
  | ICapa
  | ICapa[]
  | CapaMetadata
  | Guid
  | Guid[]
  | IAttachments
  | IAttachments[]
  | IAttachmentToken
  | IComplaintEvent[]
  | IWorkflowInstance[]
  | null;

export interface ICapaAction
  extends IDispatchBaseActionResult<CapaReducerPayload, any> {}

export interface ICapaState extends IAuditable {
  loading: boolean;
  capas: IAuditableResult<ICapa[], any> | null;
  searchResults: IAuditableResult<ICapa[], any> | null;
  capaDetails: IAuditableResult<ICapa, any> | null;
  capasMetadata: IAuditableResult<CapaMetadata, any> | null;
  attachmentToken: IAuditableResult<IAttachmentToken, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  workflows: IAuditableResult<IWorkflowInstance[], any> | null;
  error: any | null;
}

const initialState: ICapaState = {
  loading: false,
  capas: new AuditableResult<ICapa[], any>(),
  searchResults: new AuditableResult<ICapa[], any>(),
  capaDetails: new AuditableResult<ICapa, any>(),
  capasMetadata: new AuditableResult<CapaMetadata, any>(),
  attachmentToken: new AuditableResult<IAttachmentToken, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  workflows: new AuditableResult<IWorkflowInstance[], any>(),
  error: null,
};

const capaReducer = (
  state = initialState,
  action: ICapaAction
): ICapaState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_CAPAS):
      return {
        ...state,
        capas: {
          loading: true,
          data: action.loadMore ? state.capas!.data : null,
        },
      };
    case ActionModifier.request(types.SEARCH_CAPAS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_CAPAS_METADATA):
      return {
        ...state,
        capasMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_CAPA):
      return {
        ...state,
        capaDetails: {
          loading: true,
          data: null,
        },
      };
      case ActionModifier.request(types.SAVE_CAPA):
      case ActionModifier.request(types.UPDATE_CAPA):
        return {
          ...state,
          capaDetails: {
            saving: true,
            data: (state.capaDetails
              ? state.capaDetails.data
              : null) as ICapa,
          },
        };
    case ActionModifier.request(types.DELETE_CAPAS):
      return {
        ...state,
        capas: {
          loading: true,
          data: (state.capas ? state.capas.data : null) as ICapa[],
        },
      };
    case ActionModifier.request(types.SAVE_CAPA_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_CAPA_ATTACHMENT):
    case ActionModifier.request(types.DELETE_CAPA_ATTACHMENT):
      return {
        ...state,
        capaDetails: {
          loading: true,
          data: (state.capaDetails ? state.capaDetails.data : null) as ICapa,
        },
      };
    case ActionModifier.request(types.GET_CAPA_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_CAPA_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_CAPA_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: true,
          data: null,
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_CAPAS):
      return {
        ...state,
        capas: {
          loading: false,
          data: action.loadMore ? [...state.capas!.data, ...(action.payload as ICapa[])] : action.payload as ICapa[],
        },
      };
    case ActionModifier.success(types.SEARCH_CAPAS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as ICapa[],
        },
      };
    case ActionModifier.success(types.GET_CAPAS_METADATA):
      return {
        ...state,
        capasMetadata: {
          loading: false,
          data: action.payload as CapaMetadata,
        },
      };
    case ActionModifier.success(types.GET_CAPA):
      return {
        ...state,
        capaDetails: {
          loading: false,
          data: action.payload as ICapa,
        },
      };
    case ActionModifier.success(types.SAVE_CAPA_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_CAPA_ATTACHMENT):
      return {
        ...state,
        capaDetails: {
          loading: false,
          data: (state.capaDetails ? state.capaDetails.data : null) as ICapa,
        },
      };
    case ActionModifier.success(types.SAVE_CAPA):
    case ActionModifier.success(types.UPDATE_CAPA):
      return {
        ...state,
        capaDetails: {
          saving: false,
          data: (state.capaDetails
            ? state.capaDetails.data
            : null) as ICapa,
        },
      };
    case ActionModifier.success(types.DELETE_CAPAS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.capas?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        capas: {
          loading: false,
          data: newData as ICapa[],
        },
      };
    case ActionModifier.success(types.GET_CAPA_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: false,
          data: action.payload as IAttachmentToken,
        },
      };
    case ActionModifier.success(types.DELETE_CAPA_ATTACHMENT):
      const idCapaAttachment: Guid = action.payload as any;
      return {
        ...state,
        capas: {
          loading: false,
          data: state.capas?.data?.map(
                (nc) => ({ ...nc, attachments: nc.attachments.filter(e => e.id !== idCapaAttachment)})) as ICapa[]
        },
        capaDetails: {
          loading: false,
          data: { ...state.capaDetails?.data as ICapa,
                  attachments: state.capaDetails?.data?.attachments
                                .filter(e => e.id !== idCapaAttachment) as IAttachments[]
          }
        }
      };
    case ActionModifier.success(types.GET_CAPA_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (event1, event2) =>
              event2.eventTime.localeCompare(event1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_CAPA_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: action.payload as IWorkflowInstance[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_CAPAS):
      return {
        ...state,
        capas: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_CAPAS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_CAPAS_METADATA):
      return {
        ...state,
        capasMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_CAPA):
      return {
        ...state,
        capaDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_CAPA):
    case ActionModifier.failure(types.UPDATE_CAPA):
      return {
        ...state,
        capaDetails: {
          saving: false,
          data: (state.capaDetails
            ? state.capaDetails.data
            : null) as ICapa,
        },
      };
    case ActionModifier.failure(types.DELETE_CAPAS):
      return {
        ...state,
        capas: {
          loading: false,
          data: (state.capas ? state.capas.data : null) as ICapa[],
        },
        error: action.payload as any,
      };

    case ActionModifier.failure(types.GET_CAPA_ATTACHMENT_TOKEN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_CAPA_ATTACHMENT):
      return {
        ...state,
        capaDetails: {
          loading: false,
          data: (state.capaDetails ? state.capaDetails.data : null) as ICapa,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_CAPA_ATTACHMENT):
      return {
        ...state,
        capaDetails: {
          loading: false,
          data: (state.capaDetails ? state.capaDetails.data : null) as ICapa,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_CAPA_ATTACHMENT):
      return {
        ...state,
        capaDetails: {
          loading: false,
          data: (state.capaDetails ? state.capaDetails.data : null) as ICapa,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_CAPA_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_CAPA):
      return {
        ...state,
        capaDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_CAPA_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case ActionModifier.failure(types.GET_CAPA_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    default:
      return state;
  }
};

export default capaReducer;
