import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./reducers/rootReducer";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

function configureStore() {
  const composeEnhancers = composeWithDevTools({
  });
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );
}

const store = configureStore();

export default store;
