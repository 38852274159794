import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {ElectricityItem} from "../../domain/electricity/ElectricityItem";

type ElectricityReducerPayload =
  | ElectricityItem
  | ElectricityItem[]
  | Guid
  | Guid[]
  | null;

export interface IElectricityAction
  extends IDispatchBaseActionResult<ElectricityReducerPayload, any> {}

export interface IElectricityState extends IAuditable {
  loading: boolean;
  electricityItems: IAuditableResult<ElectricityItem[], any> | null;
  electricityItemDetails: IAuditableResult<ElectricityItem, any> | null;
  error: any | null;
}

const initialState: IElectricityState = {
  loading: false,
  electricityItems: new AuditableResult<ElectricityItem[], any>(),
  electricityItemDetails: new AuditableResult<ElectricityItem, any>(),
  error: null,
};

const electricityReducer = (
  state = initialState,
  action: IElectricityAction
): IElectricityState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_ELECTRICITY_ITEMS):
      return {
        ...state,
        electricityItems: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItemDetails: {
          loading: true,
          data: null,
        },
      };
      case ActionModifier.request(types.SAVE_ELECTRICITY_ITEM):
      case ActionModifier.request(types.UPDATE_ELECTRICITY_ITEM):
        return {
          ...state,
          electricityItemDetails: {
            saving: true,
            data: (state.electricityItemDetails ? state.electricityItemDetails.data : null) as ElectricityItem,
          },
        };
    case ActionModifier.request(types.DELETE_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItems: {
          loading: true,
          data: (state.electricityItems ? state.electricityItems.data : null) as ElectricityItem[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_ELECTRICITY_ITEMS):
      return {
        ...state,
        electricityItems: {
          loading: false,
          data: action.payload as ElectricityItem[],
        },
      };
    case ActionModifier.success(types.GET_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItemDetails: {
          loading: false,
          data: action.payload as ElectricityItem,
        },
      };
    case ActionModifier.success(types.SAVE_ELECTRICITY_ITEM):
    case ActionModifier.success(types.UPDATE_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItemDetails: {
          saving: false,
          data: (state.electricityItemDetails ? state.electricityItemDetails.data : null) as ElectricityItem,
        },
      };
    case ActionModifier.success(types.DELETE_ELECTRICITY_ITEM):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.electricityItems?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        electricityItems: {
          loading: false,
          data: newData as ElectricityItem[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_ELECTRICITY_ITEMS):
      return {
        ...state,
        electricityItems: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItemDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_ELECTRICITY_ITEM):
    case ActionModifier.failure(types.UPDATE_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItemDetails: {
          saving: false,
          data: (state.electricityItemDetails ? state.electricityItemDetails.data : null) as ElectricityItem,
        },
      };
    case ActionModifier.failure(types.DELETE_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItems: {
          loading: false,
          data: (state.electricityItems ? state.electricityItems.data : null) as ElectricityItem[],
        },
        error: action.payload as any,
      };


    // DISPOSE
    case ActionModifier.dispose(types.GET_ELECTRICITY_ITEM):
      return {
        ...state,
        electricityItemDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default electricityReducer;
