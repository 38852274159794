import { TypedUseSelectorHook, useSelector } from "react-redux";
import { DashboardState } from "../../types";
import { RootState } from "./rootReducer";

type args = { type?: string; [key: string]: any };

const initialState: DashboardState = {
  darkMode: false,
  asideShow: false,
  sidebarShow: true,
};

export function dashboardReducer(
  state = initialState,
  { type, ...rest }: args
) {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
