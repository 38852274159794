import * as types from "../actions/actionTypes";
import IDispatchBaseActionResult from "../entities/IDispatchBaseActionResults";
import IAuditable from "../entities/IAuditable";
import IAuditableResult from "../entities/IAuditableResult";
import AuditableResult from "../entities/AuditableResult";
import {ActionModifier} from "../action-creators/ActionModifier";
import {User} from "../../domain/users/User";
import {Ability} from "@casl/ability";
import defineAbilitiesFor from '../../utils/ability';
import {ICustomerLogo, ITenantSettings} from "../../domain/common/common";
import {IExternalUser} from "../../domain/users/IExternalUser";
import {ISiteUsersMinimal, IUserMinimal} from "../../domain/common/interface";
import {IDocument} from "../../domain/documents/Document";
import {Audit} from "../../domain/audits/Audit";

type UserReducerPayload =
  | User
  | User[]
  | IExternalUser[]
  | ICustomerLogo
  | ITenantSettings
  | ISiteUsersMinimal
  | IUserMinimal[]
  | IDocument[]
  | Audit[]
  | null;

export interface IUserAction
  extends IDispatchBaseActionResult<UserReducerPayload, any> {}

export interface IUserState extends IAuditable {
  user: IAuditableResult<User, any> | null;
  users: IAuditableResult<User[], any> | null;
  externalUsers: IAuditableResult<IExternalUser[], any> | null;
  siteUsers: IAuditableResult<ISiteUsersMinimal[], any> | null;
  logo: IAuditableResult<ICustomerLogo, any> | null;
  tenantSettings: IAuditableResult<ITenantSettings, any> | null;
  myDocuments: IAuditableResult<IDocument[], any> | null;
  myAudits: IAuditableResult<Audit[], any> | null;
  abilities: Ability;
  error: any | null;
}

const initialState: IUserState = {
  loading: false,
  abilities: defineAbilitiesFor(''),
  user: new AuditableResult<User, any>(),
  users: new AuditableResult<User[], any>(),
  externalUsers: new AuditableResult<IExternalUser[], any>(),
  siteUsers: new AuditableResult<ISiteUsersMinimal[], any>([]),
  myDocuments: new AuditableResult<IDocument[], any>([]),
  myAudits: new AuditableResult<Audit[], any>([]),
  logo: new AuditableResult<ICustomerLogo, any>(),
  tenantSettings: new AuditableResult<ITenantSettings, any>(),
  error: null,
};

const userReducer = (
  state = initialState,
  action: IUserAction
): IUserState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_USER_INFO):
      return {
        ...state,
        user: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_LOGO):
      return {
        ...state,
        logo: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_TENANT_SETTINGS):
      return {
        ...state,
        tenantSettings: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_MY_DOCUMENTS):
      return {
        ...state,
        myDocuments: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_MY_AUDITS):
      return {
        ...state,
        myAudits: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_ALL_USERS):
      return {
        ...state,
        users: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EXTERNAL_USERS):
      return {
        ...state,
        externalUsers: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SITE_USERS):
      return {
        ...state,
        siteUsers: {
          loading: true,
          data: state.siteUsers ? state.siteUsers.data : [],
        },
      };
    case ActionModifier.request(types.SET_USER_SELECTED_SITE):
      return {
        ...state,
        loading: true,
      };

    // SUCCESS
    case ActionModifier.success(types.GET_USER_INFO):
      return {
        ...state,
        user: {
          loading: false,
          data: action.payload as User,
        },
        abilities: defineAbilitiesFor((action.payload as User).selectedRole.name)
      };
    case ActionModifier.success(types.GET_LOGO):
      return {
        ...state,
        logo: {
          loading: false,
          data: action.payload as ICustomerLogo,
        },
      };
    case ActionModifier.success(types.GET_TENANT_SETTINGS):
      return {
        ...state,
        tenantSettings: {
          loading: false,
          data: action.payload as ITenantSettings,
        },
      };
    case ActionModifier.success(types.GET_MY_DOCUMENTS):
      return {
        ...state,
        myDocuments: {
          loading: false,
          data: action.payload as IDocument[],
        },
      };
    case ActionModifier.success(types.GET_MY_AUDITS):
      return {
        ...state,
        myAudits: {
          loading: false,
          data: action.payload as Audit[],
        },
      };
    case ActionModifier.success(types.GET_ALL_USERS):
      return {
        ...state,
        users: {
          loading: false,
          data: action.payload as User[],
        },
      };
    case ActionModifier.success(types.GET_EXTERNAL_USERS):
      return {
        ...state,
        externalUsers: {
          loading: false,
          data: action.payload as IExternalUser[],
        },
      };
    case ActionModifier.success(types.GET_SITE_USERS):
      const siteUsers = state.siteUsers && state.siteUsers.data ? state.siteUsers.data.filter(site => site.id !== (action.payload as ISiteUsersMinimal).id) : [];
      return {
        ...state,
        siteUsers: {
          loading: false,
          data: [
            ...siteUsers,
            action.payload as ISiteUsersMinimal
          ],
        },
      };
    case ActionModifier.success(types.SET_USER_SELECTED_SITE):
      return {
        ...state,
        loading: false,
      };

    // FAILURE
    case ActionModifier.failure(types.GET_USER_INFO):
      return {
        ...state,
        user: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_LOGO):
      return {
        ...state,
        logo: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_TENANT_SETTINGS):
      return {
        ...state,
        tenantSettings: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_MY_DOCUMENTS):
      return {
        ...state,
        myDocuments: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_MY_AUDITS):
      return {
        ...state,
        myAudits: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ALL_USERS):
      return {
        ...state,
        users: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EXTERNAL_USERS):
      return {
        ...state,
        externalUsers: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_SITE_USERS):
      return {
        ...state,
        siteUsers: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SET_USER_SELECTED_SITE):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    default:
      return state;
  }
};

export default userReducer;

