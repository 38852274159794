import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {IDocument} from "../../domain/documents/Document";
import {DocumentsMetadata} from "../../domain/documents/DocumentsMetadata";
import {IComplaintEvent} from "../../domain/complaint-events/ComplaintEvent";
import {IWorkflowInstance} from "../../domain/workflow-instances/IWorkflowInstance";
import {IAttachmentToken} from "../../domain/attachments/IAttachmentToken";

type DocumentReducerPayload =
  | IDocument
  | IDocument[]
  | DocumentsMetadata
  | IWorkflowInstance[]
  | IComplaintEvent[]
  | IAttachmentToken
  | Guid
  | Guid[]
  | null;

export interface IDocumentAction
  extends IDispatchBaseActionResult<DocumentReducerPayload, any> {}

export interface IDocumentState extends IAuditable {
  loading: boolean;
  documents: IAuditableResult<IDocument[], any> | null;
  documentVersions: IAuditableResult<IDocument[], any> | null;
  documentDetails: IAuditableResult<IDocument, any> | null;
  documentsMetadata: IAuditableResult<DocumentsMetadata, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  workflows: IAuditableResult<IWorkflowInstance[], any> | null;
  searchResults: IAuditableResult<IDocument[], any> | null;
  error: any | null;
}

const initialState: IDocumentState = {
  loading: false,
  documents: new AuditableResult<IDocument[], any>(),
  documentVersions: new AuditableResult<IDocument[], any>(),
  documentDetails: new AuditableResult<IDocument, any>(),
  documentsMetadata: new AuditableResult<DocumentsMetadata, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  workflows: new AuditableResult<IWorkflowInstance[], any>(),
  searchResults: new AuditableResult<IDocument[], any>(),
  error: null,
};

const documentReducer = (
  state = initialState,
  action: IDocumentAction
): IDocumentState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_DOCUMENTS):
      return {
        ...state,
        documents: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_DOCUMENT_VERSIONS):
      return {
        ...state,
        documentVersions: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.START_DOCUMENT_APPROVAL):
      return {
        ...state,
        documentDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SAVE_DOCUMENT):
    case ActionModifier.request(types.UPDATE_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          saving: true,
          data: (state.documentDetails ? state.documentDetails.data : null) as IDocument,
        },
      };
    case ActionModifier.request(types.CREATE_COLLABORATION_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          updating: true,
          data: (state.documentDetails ? state.documentDetails.data : null) as IDocument,
        },
      };
    case ActionModifier.request(types.DELETE_DOCUMENT):
      return {
        ...state,
        documents: {
          loading: true,
          data: (state.documents ? state.documents.data : null) as IDocument[],
        },
      };
    case ActionModifier.request(types.GET_DOCUMENTS_METADATA):
      return {
        ...state,
        documentsMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_DOCUMENT_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_DOCUMENT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SEARCH_DOCUMENTS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_DOCUMENTS):
      return {
        ...state,
        documents: {
          loading: false,
          data: action.payload as IDocument[],
        },
      };
    case ActionModifier.success(types.GET_DOCUMENT_VERSIONS):
      return {
        ...state,
        documentVersions: {
          loading: false,
          data: action.payload as IDocument[],
        },
      };
    case ActionModifier.success(types.GET_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          loading: false,
          data: action.payload as IDocument,
        },
      };
    case ActionModifier.success(types.START_DOCUMENT_APPROVAL):
      return {
        ...state,
        documentDetails: {
          loading: false,
          data: null,
        },
      };
    case ActionModifier.success(types.SAVE_DOCUMENT):
    case ActionModifier.success(types.UPDATE_DOCUMENT):
    case ActionModifier.success(types.CREATE_COLLABORATION_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          saving: false,
          updating: false,
          data: action.payload as IDocument,
        },
      };
    case ActionModifier.success(types.DELETE_DOCUMENT):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.documents?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        documents: {
          loading: false,
          data: newData as IDocument[],
        },
      };
    case ActionModifier.success(types.GET_DOCUMENTS_METADATA):
      return {
        ...state,
        documentsMetadata: {
          loading: false,
          data: action.payload as DocumentsMetadata,
        },
      };
    case ActionModifier.success(types.GET_DOCUMENT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (complaintEvent1, complaintEvent2) =>
              complaintEvent2.eventTime.localeCompare(complaintEvent1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_DOCUMENT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: action.payload as IWorkflowInstance[],
        },
      };
    case ActionModifier.success(types.SEARCH_DOCUMENTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as IDocument[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_DOCUMENTS):
      return {
        ...state,
        documents: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DOCUMENT_VERSIONS):
      return {
        ...state,
        documentVersions: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.START_DOCUMENT_APPROVAL):
      return {
        ...state,
        documentDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_DOCUMENT):
    case ActionModifier.failure(types.UPDATE_DOCUMENT):
    case ActionModifier.failure(types.CREATE_COLLABORATION_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          saving: false,
          updating: false,
          data: (state.documentDetails ? state.documentDetails.data : null) as IDocument,
        },
      };
    case ActionModifier.failure(types.DELETE_DOCUMENT):
      return {
        ...state,
        documents: {
          loading: false,
          data: (state.documents ? state.documents.data : null) as IDocument[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_DOCUMENTS_METADATA):
      return {
        ...state,
        documentsMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DOCUMENT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DOCUMENT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_DOCUMENTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };


    // DISPOSE
    case ActionModifier.dispose(types.GET_DOCUMENT):
      return {
        ...state,
        documentDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default documentReducer;
