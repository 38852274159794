import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import { SafetyIncident } from "../../domain/safety-incidents/SafetyIncident";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { IAttachments } from "domain/attachments/Attachment";
import { IAttachmentToken } from "domain/attachments/IAttachmentToken";
import { IComplaintEvent } from "../../domain/complaint-events/ComplaintEvent";
import { SafetyIncidentMetadata } from "domain/safety-incidents/SafetyIncidentMetadata";
import {IWorkflowInstance} from "../../domain/workflow-instances/IWorkflowInstance";

type SafetyIncidentReducerPayload =
  | SafetyIncident
  | SafetyIncident[]
  | SafetyIncidentMetadata
  | Guid
  | Guid[]
  | IAttachments
  | IAttachments[]
  | IAttachmentToken
  | IWorkflowInstance[]
  | IComplaintEvent[]
  | null;

export interface ISafetyIncidentAction
  extends IDispatchBaseActionResult<SafetyIncidentReducerPayload, any> {}

export interface ISafetyIncidentState extends IAuditable {
  loading: boolean;
  refreshCapas: boolean;
  safetyIncidents: IAuditableResult<SafetyIncident[], any> | null;
  searchResults: IAuditableResult<SafetyIncident[], any> | null;
  safetyIncidentDetails: IAuditableResult<SafetyIncident, any> | null;
  safetyIncidentsMetadata: IAuditableResult<SafetyIncidentMetadata, any> | null;
  attachmentToken: IAuditableResult<IAttachmentToken, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  workflows: IAuditableResult<IWorkflowInstance[], any> | null;
  error: any | null;
}

const initialState: ISafetyIncidentState = {
  loading: false,
  refreshCapas: false,
  safetyIncidents: new AuditableResult<SafetyIncident[], any>(),
  searchResults: new AuditableResult<SafetyIncident[], any>(),
  safetyIncidentDetails: new AuditableResult<SafetyIncident, any>(),
  safetyIncidentsMetadata: new AuditableResult<SafetyIncidentMetadata, any>(),
  attachmentToken: new AuditableResult<IAttachmentToken, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  workflows: new AuditableResult<IWorkflowInstance[], any>(),
  error: null,
};

const safetyIncidentReducer = (
  state = initialState,
  action: ISafetyIncidentAction
): ISafetyIncidentState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_SAFETY_INCIDENTS):
      return {
        ...state,
        safetyIncidents: {
          loading: true,
          data: action.loadMore ? state.safetyIncidents!.data : null,
        },
      };
    case ActionModifier.request(types.SEARCH_SAFETY_INCIDENTS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SAFETY_INCIDENTS_METADATA):
      return {
        ...state,
        safetyIncidentsMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SAFETY_INCIDENT_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: true,
      };
    case ActionModifier.request(types.SAVE_SAFETY_INCIDENT):
    case ActionModifier.request(types.UPDATE_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          saving: true,
          data: (state.safetyIncidentDetails
            ? state.safetyIncidentDetails.data
            : null) as SafetyIncident,
        },
      };
    case ActionModifier.request(types.DELETE_SAFETY_INCIDENTS):
      return {
        ...state,
        safetyIncidents: {
          loading: true,
          data: (state.safetyIncidents ? state.safetyIncidents.data : null) as SafetyIncident[],
        },
      };
    case ActionModifier.request(types.SAVE_SAFETY_INCIDENT_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_SAFETY_INCIDENT_ATTACHMENT):
    case ActionModifier.request(types.DELETE_SAFETY_INCIDENT_ATTACHMENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: true,
          data: (state.safetyIncidentDetails ? state.safetyIncidentDetails.data : null) as SafetyIncident,
        },
      };
    case ActionModifier.request(types.GET_SAFETY_INCIDENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SAFETY_INCIDENT_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SAFETY_INCIDENT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: true,
          data: null,
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_SAFETY_INCIDENTS):
      return {
        ...state,
        safetyIncidents: {
          loading: false,
          data: action.loadMore ? [...state.safetyIncidents!.data, ...(action.payload as SafetyIncident[])] : action.payload as SafetyIncident[],
        },
      };
    case ActionModifier.success(types.SEARCH_SAFETY_INCIDENTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as SafetyIncident[],
        },
      };
    case ActionModifier.success(types.GET_SAFETY_INCIDENTS_METADATA):
      return {
        ...state,
        safetyIncidentsMetadata: {
          loading: false,
          data: action.payload as SafetyIncidentMetadata,
        },
      };
    case ActionModifier.success(types.GET_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: false,
          data: action.payload as SafetyIncident,
        },
      };
    case ActionModifier.success(types.GET_SAFETY_INCIDENT_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: false,
        safetyIncidentDetails: {
          loading: false,
          data: action.payload as SafetyIncident,
        },
      };

    case ActionModifier.success(types.SAVE_SAFETY_INCIDENT):
    case ActionModifier.success(types.UPDATE_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          saving: false,
          data: (state.safetyIncidentDetails
            ? state.safetyIncidentDetails.data
            : null) as SafetyIncident,
        },
      };
    case ActionModifier.success(types.DELETE_SAFETY_INCIDENTS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.safetyIncidents?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        safetyIncidents: {
          loading: false,
          data: newData as SafetyIncident[],
        },
      };
    case ActionModifier.success(types.GET_SAFETY_INCIDENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: false,
          data: action.payload as IAttachmentToken,
        },
      };
    case ActionModifier.success(types.SAVE_SAFETY_INCIDENT_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_SAFETY_INCIDENT_ATTACHMENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: false,
          data: (state.safetyIncidentDetails ? state.safetyIncidentDetails.data : null) as SafetyIncident,
        },
      };
    case ActionModifier.success(types.DELETE_SAFETY_INCIDENT_ATTACHMENT):
      const idSafetyIncidentAttachment: Guid = action.payload as any;
      return {
        ...state,
        safetyIncidents: {
          loading: false,
          data: state.safetyIncidents?.data?.map(
                (nc) => ({ ...nc, attachments: nc.attachments.filter(e => e.id !== idSafetyIncidentAttachment)})) as SafetyIncident[]
        },
        safetyIncidentDetails: {
          loading: false,
          data: { ...state.safetyIncidentDetails?.data as SafetyIncident,
                  attachments: state.safetyIncidentDetails?.data?.attachments
                                .filter(e => e.id !== idSafetyIncidentAttachment) as IAttachments[]
          }
        }
      };
    case ActionModifier.success(types.GET_SAFETY_INCIDENT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (event1, event2) =>
              event2.eventTime.localeCompare(event1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_SAFETY_INCIDENT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: action.payload as IWorkflowInstance[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_SAFETY_INCIDENTS):
      return {
        ...state,
        safetyIncidents: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_SAFETY_INCIDENTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_SAFETY_INCIDENTS_METADATA):
      return {
        ...state,
        safetyIncidentsMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_SAFETY_INCIDENT_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: false,
        safetyIncidentDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_SAFETY_INCIDENT):
    case ActionModifier.failure(types.UPDATE_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          saving: false,
          data: (state.safetyIncidentDetails
            ? state.safetyIncidentDetails.data
            : null) as SafetyIncident,
        },
      };

    case ActionModifier.failure(types.DELETE_SAFETY_INCIDENTS):
      return {
        ...state,
        safetyIncidents: {
          loading: false,
          data: (state.safetyIncidents ? state.safetyIncidents.data : null) as SafetyIncident[],
        },
        error: action.payload as any,
      };

    case ActionModifier.failure(types.GET_SAFETY_INCIDENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_SAFETY_INCIDENT_ATTACHMENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: false,
          data: (state.safetyIncidentDetails ? state.safetyIncidentDetails.data : null) as SafetyIncident,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_SAFETY_INCIDENT_ATTACHMENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: false,
          data: (state.safetyIncidentDetails ? state.safetyIncidentDetails.data : null) as SafetyIncident,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_SAFETY_INCIDENT_ATTACHMENT):
      return {
        ...state,
        safetyIncidentDetails: {
          loading: false,
          data: (state.safetyIncidentDetails ? state.safetyIncidentDetails.data : null) as SafetyIncident,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_SAFETY_INCIDENT_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_SAFETY_INCIDENT):
      return {
        ...state,
        safetyIncidentDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_SAFETY_INCIDENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          data: null,
          error: null,
          loading: false,
        },
      };

    case ActionModifier.failure(types.GET_SAFETY_INCIDENT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    default:
      return state;
  }
};

export default safetyIncidentReducer;
