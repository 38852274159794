import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable'
import "core-js";
import smoothscroll from 'smoothscroll-polyfill';
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from "./redux/store";
import { Provider } from 'react-redux'
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";
import axios from "axios";
import {triggerError} from "redux/triggers/toasterTriggers";
import {IntlProvider} from "react-intl";
import messages_en from "./lang/en.json";
import messages_fr from "./lang/fr.json";
import messages_nl from "./lang/nl.json";

require("dotenv").config();
smoothscroll.polyfill();

export const msalInstance = new PublicClientApplication(msalConfig);
const messages = {
  'en': messages_en,
  'fr': messages_fr,
  'nl': messages_nl
};

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE){
    console.error(event.error);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  } else if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
  }
});

axios.interceptors.response.use(response => {
  if (response.headers['qesh-continuationtoken']) {
    if (response.headers['qesh-continuationtoken'] === 'null') {
      localStorage.removeItem('qesh-continuationtoken');
    } else {
      localStorage.setItem('qesh-continuationtoken', response.headers['qesh-continuationtoken']);
    }
  }
  return response;
}, error => {
  if (error.response.status === 401) {
    triggerError(error.message, 'toast.error.401')
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    }, 3000)
  } else if (error.response.status === 412) {
    triggerError(error.message, 'toast.error.412')
  } else if (error.response.status === 403) {
    triggerError(error.message, 'toast.error.403')
  }
  throw error;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider locale={navigator.language.split('-')[0]} messages={messages[navigator.language.split('-')[0]]}>
        <App pca={msalInstance}/>
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
