import { disposeToaster, setToaster, ToasterType } from 'redux/reducers/toasterReducer';
import store from '../store';

export const triggerSuccess = (message: string, title: string) => store.dispatch(setToaster({
    type: ToasterType.Success,
    message,
    title
}) as any);

export const triggerError = (message: string, title: string) => store.dispatch(setToaster({
    type: ToasterType.Error,
    message,
    title
}) as any);

export const triggerDispose = () => store.dispatch(disposeToaster() as any);