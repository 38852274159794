import { Dispatch } from "react";
import IDispatchBaseActionResult from "../entities/IDispatchBaseActionResults";

type ToasterReducerPayload = {
  type: ToasterType;
  message: string;
  title: string;
}

export enum ToasterType {
  Error,
  Success,
  Warning,
  Info
}

export const SET_TOASTER = 'toast/SET_TOASTER';
export const DISPOSE_TOASTER = 'toast/DISPOSE_TOASTER';

export interface IToasterAction
  extends IDispatchBaseActionResult<ToasterReducerPayload, any> {}

export interface IToasterState {
  type?: ToasterType;
  message?: string;
  title?: string;
}


const userReducer = (
  state = {},
  action: IToasterAction
): IToasterState => {
  switch (action.type) {
    case SET_TOASTER:
      return {
        type: action.payload?.type,
        message: action.payload?.message,
        title: action.payload?.title,
      }
    case DISPOSE_TOASTER:
      return {};
    default:
      return state;
  }
};


export const setToaster = (payload: ToasterReducerPayload) => {
  return async (dispatch: Dispatch<IToasterAction>) => {
    dispatch({
      type: SET_TOASTER,
      payload
    });
  }
}

export const disposeToaster = () => {
  return async (dispatch: Dispatch<IToasterAction>) => {
    dispatch({
      type: DISPOSE_TOASTER
    });
  }
}

export default userReducer;
