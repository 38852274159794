import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import { NonConformity } from "../../domain/non-conformities/NonConformity";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { NonConformityMetadata } from "domain/non-conformities/NonConformityMetadata";
import { IAttachments } from "domain/attachments/Attachment";
import { IAttachmentToken } from "domain/attachments/IAttachmentToken";
import { IComplaintEvent } from "../../domain/complaint-events/ComplaintEvent";
import { IWorkflowInstance } from "domain/workflow-instances/IWorkflowInstance";

type NonConformityReducerPayload =
  | NonConformity
  | NonConformity[]
  | NonConformityMetadata
  | Guid
  | Guid[]
  | IAttachments
  | IAttachments[]
  | IAttachmentToken
  | IWorkflowInstance[]
  | IComplaintEvent[]
  | null;

export interface INonConformityAction
  extends IDispatchBaseActionResult<NonConformityReducerPayload, any> {}

export interface INonConformityState extends IAuditable {
  loading: boolean;
  refreshCapas: boolean;
  nonConformities: IAuditableResult<NonConformity[], any> | null;
  searchResults: IAuditableResult<NonConformity[], any> | null;
  nonConformityDetails: IAuditableResult<NonConformity, any> | null;
  nonConformitiesMetadata: IAuditableResult<NonConformityMetadata, any> | null;
  attachmentToken: IAuditableResult<IAttachmentToken, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  workflows: IAuditableResult<IWorkflowInstance[], any> | null;
  error: any | null;
}

const initialState: INonConformityState = {
  loading: false,
  refreshCapas: false,
  nonConformities: new AuditableResult<NonConformity[], any>(),
  searchResults: new AuditableResult<NonConformity[], any>(),
  nonConformityDetails: new AuditableResult<NonConformity, any>(),
  nonConformitiesMetadata: new AuditableResult<NonConformityMetadata, any>(),
  attachmentToken: new AuditableResult<IAttachmentToken, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  workflows: new AuditableResult<IWorkflowInstance[], any>(),
  error: null,
};

const nonConformityReducer = (
  state = initialState,
  action: INonConformityAction
): INonConformityState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_NON_CONFORMITIES):
      return {
        ...state,
        nonConformities: {
          loading: true,
          data: action.loadMore ? state.nonConformities!.data : null,
        },
      };
    case ActionModifier.request(types.SEARCH_NON_CONFORMITIES):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_NON_CONFORMITIES_METADATA):
      return {
        ...state,
        nonConformitiesMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_NON_CONFORMITY_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: true,
      };
    case ActionModifier.request(types.SAVE_NON_CONFORMITY):
    case ActionModifier.request(types.UPDATE_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          saving: true,
          data: (state.nonConformityDetails
            ? state.nonConformityDetails.data
            : null) as NonConformity,
        },
      };
    case ActionModifier.request(types.DELETE_NON_CONFORMITIES):
      return {
        ...state,
        nonConformities: {
          loading: true,
          data: (state.nonConformities ? state.nonConformities.data : null) as NonConformity[],
        },
      };
    case ActionModifier.request(types.SAVE_NON_CONFORMITY_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_NON_CONFORMITY_ATTACHMENT):
    case ActionModifier.request(types.DELETE_NON_CONFORMITY_ATTACHMENT):
      return {
        ...state,
        nonConformityDetails: {
          loading: true,
          data: (state.nonConformityDetails ? state.nonConformityDetails.data : null) as NonConformity,
        },
      };
    case ActionModifier.request(types.GET_NON_CONFORMITY_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_NON_CONFORMITY_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_NON_CONFORMITY_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: true,
          data: null,
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_NON_CONFORMITIES):
      return {
        ...state,
        nonConformities: {
          loading: false,
          data: action.loadMore ? [...state.nonConformities!.data, ...(action.payload as NonConformity[])] : action.payload as NonConformity[],
        },
      };
    case ActionModifier.success(types.SEARCH_NON_CONFORMITIES):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as NonConformity[],
        },
      };
    case ActionModifier.success(types.GET_NON_CONFORMITIES_METADATA):
      return {
        ...state,
        nonConformitiesMetadata: {
          loading: false,
          data: action.payload as NonConformityMetadata,
        },
      };
    case ActionModifier.success(types.GET_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          loading: false,
          data: action.payload as NonConformity,
        },
      };
    case ActionModifier.success(types.SAVE_NON_CONFORMITY_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_NON_CONFORMITY_ATTACHMENT):
      return {
        ...state,
        nonConformityDetails: {
          loading: false,
          data: (state.nonConformityDetails ? state.nonConformityDetails.data : null) as NonConformity,
        },
      };
    case ActionModifier.success(types.GET_NON_CONFORMITY_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: false,
        nonConformityDetails: {
          loading: false,
          data: action.payload as NonConformity,
        },
      };

    case ActionModifier.success(types.SAVE_NON_CONFORMITY):
    case ActionModifier.success(types.UPDATE_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          saving: false,
          data: (state.nonConformityDetails
            ? state.nonConformityDetails.data
            : null) as NonConformity,
        },
      };

    case ActionModifier.success(types.DELETE_NON_CONFORMITIES):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.nonConformities?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        nonConformities: {
          loading: false,
          data: newData as NonConformity[],
        },
      };
    case ActionModifier.success(types.GET_NON_CONFORMITY_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: false,
          data: action.payload as IAttachmentToken,
        },
      };
    case ActionModifier.success(types.DELETE_NON_CONFORMITY_ATTACHMENT):
      const idNonConformityAttachment: Guid = action.payload as any;
      return {
        ...state,
        nonConformities: {
          loading: false,
          data: state.nonConformities?.data?.map(
                (nc) => ({ ...nc, attachments: nc.attachments.filter(e => e.id !== idNonConformityAttachment)})) as NonConformity[]
        },
        nonConformityDetails: {
          loading: false,
          data: { ...state.nonConformityDetails?.data as NonConformity,
                  attachments: state.nonConformityDetails?.data?.attachments
                                .filter(e => e.id !== idNonConformityAttachment) as IAttachments[]
          }
        }
      };
    case ActionModifier.success(types.GET_NON_CONFORMITY_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (complaintEvent1, complaintEvent2) =>
              complaintEvent2.eventTime.localeCompare(complaintEvent1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_NON_CONFORMITY_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: action.payload as IWorkflowInstance[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_NON_CONFORMITIES):
      return {
        ...state,
        nonConformities: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_NON_CONFORMITIES):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_NON_CONFORMITIES_METADATA):
      return {
        ...state,
        nonConformitiesMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_NON_CONFORMITY_REFRESH_CAPAS):
      return {
        ...state,
        refreshCapas: false,
        nonConformityDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_NON_CONFORMITY):
    case ActionModifier.failure(types.UPDATE_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          saving: false,
          data: (state.nonConformityDetails
            ? state.nonConformityDetails.data
            : null) as NonConformity,
        },
      };

    case ActionModifier.failure(types.DELETE_NON_CONFORMITIES):
      return {
        ...state,
        nonConformities: {
          loading: false,
          data: (state.nonConformities ? state.nonConformities.data : null) as NonConformity[],
        },
        error: action.payload as any,
      };

    case ActionModifier.failure(types.GET_NON_CONFORMITY_ATTACHMENT_TOKEN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_NON_CONFORMITY_ATTACHMENT):
      return {
        ...state,
        nonConformityDetails: {
          loading: false,
          data: (state.nonConformityDetails ? state.nonConformityDetails.data : null) as NonConformity,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_NON_CONFORMITY_ATTACHMENT):
      return {
        ...state,
        nonConformityDetails: {
          loading: false,
          data: (state.nonConformityDetails ? state.nonConformityDetails.data : null) as NonConformity,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_NON_CONFORMITY_ATTACHMENT):
      return {
        ...state,
        nonConformityDetails: {
          loading: false,
          data: (state.nonConformityDetails ? state.nonConformityDetails.data : null) as NonConformity,
        },
        error: action.payload as any,
      };
    // DISPOSE
    case ActionModifier.dispose(types.GET_NON_CONFORMITY):
      return {
        ...state,
        nonConformityDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_NON_CONFORMITY_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.failure(types.GET_NON_CONFORMITY_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_NON_CONFORMITY_WORKFLOWS):
      return {
        ...state,
        workflows: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    default:
      return state;
  }
};

export default nonConformityReducer;
