import { isUndefined } from "underscore";
import IAuditableResult from "./IAuditableResult";

class AuditableResult<TEntity, TError>
  implements IAuditableResult<TEntity, TError>
{
  data: TEntity | null;
  error: TError | null;
  loading: boolean;
  saving: boolean;

  constructor(initialState?: TEntity) {
    this.loading = false;
    this.saving = false;
    this.data = !isUndefined(initialState) ? initialState : null;
    this.error = null;
  }
}

export default AuditableResult;
