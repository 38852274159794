import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import {Audit, AuditIteration} from "../../domain/audits/Audit";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { IAttachments } from "domain/attachments/Attachment";
import { IAttachmentToken } from "domain/attachments/IAttachmentToken";
import { IComplaintEvent } from "../../domain/complaint-events/ComplaintEvent";
import {QuestionTemplate} from "../../domain/audits/QuestionTemplate";
import {QuestionTemplateMetadata} from "../../domain/audits/QuestionTemplateMetadata";
import {
  AuditTaxonomyItem,
  AuditTaxonomyTemplate,
  AuditTaxonomyTemplateItem
} from "../../domain/audits/AuditTaxonomyTemplate";
import {AuditTaxonomyTemplateMetadata} from "../../domain/audits/AuditTaxonomyTemplateMetadata";

type AuditReducerPayload =
  | Audit
  | Audit[]
  | AuditIteration
  | AuditIteration[]
  | QuestionTemplate
  | QuestionTemplate[]
  | QuestionTemplateMetadata
  | AuditTaxonomyTemplate
  | AuditTaxonomyTemplate[]
  | AuditTaxonomyTemplateMetadata
  | AuditTaxonomyTemplateItem[]
  | AuditTaxonomyItem[]
  | Guid
  | Guid[]
  | IAttachments
  | IAttachmentToken
  | IAttachments[]
  | IComplaintEvent[]
  | string
  | null;

export interface IAuditAction
  extends IDispatchBaseActionResult<AuditReducerPayload, any> {}

export interface IAuditState extends IAuditable {
  loading: boolean;
  loadingItems: boolean;
  currentAuditIterationId: string;
  auditDetails: IAuditableResult<Audit, any> | null;
  auditIterations: IAuditableResult<AuditIteration[], any> | null;
  auditTemplateQuestions: IAuditableResult<QuestionTemplate[], any> | null;
  questionTemplateDetails: IAuditableResult<QuestionTemplate, any> | null;
  templateDetails: IAuditableResult<AuditTaxonomyTemplate, any> | null;
  audits: IAuditableResult<Audit[], any> | null;
  questionTemplates: IAuditableResult<QuestionTemplate[], any> | null;
  templates: IAuditableResult<AuditTaxonomyTemplate[], any> | null;
  searchResults: IAuditableResult<Audit[], any> | null;
  questionTemplatesMetadata: IAuditableResult<QuestionTemplateMetadata, any> | null;
  templatesMetadata: IAuditableResult<AuditTaxonomyTemplateMetadata, any> | null;
  attachments: IAuditableResult<IAttachments[], any> | null;
  attachmentToken: IAuditableResult<IAttachmentToken, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  error: any | null;
}

const initialState: IAuditState = {
  loading: false,
  loadingItems: false,
  currentAuditIterationId: '',
  auditDetails: new AuditableResult<Audit, any>(),
  auditIterations: new AuditableResult<AuditIteration[], any>(),
  auditTemplateQuestions: new AuditableResult<QuestionTemplate[], any>(),
  questionTemplateDetails: new AuditableResult<QuestionTemplate, any>(),
  templateDetails: new AuditableResult<AuditTaxonomyTemplate, any>(),
  audits: new AuditableResult<Audit[], any>(),
  questionTemplates: new AuditableResult<QuestionTemplate[], any>(),
  templates: new AuditableResult<AuditTaxonomyTemplate[], any>(),
  searchResults: new AuditableResult<Audit[], any>(),
  questionTemplatesMetadata: new AuditableResult<QuestionTemplateMetadata, any>(),
  templatesMetadata: new AuditableResult<AuditTaxonomyTemplateMetadata, any>(),
  attachments: new AuditableResult<IAttachments[], any>(),
  attachmentToken: new AuditableResult<IAttachmentToken, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  error: null,
};

const auditReducer = (
  state = initialState,
  action: IAuditAction
): IAuditState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_AUDITS):
      return {
        ...state,
        audits: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_ALL_QUESTION_TEMPLATES):
      return {
        ...state,
        questionTemplates: {
          loading: true,
          data: action.loadMore ? state.questionTemplates!.data : null,
        },
      };
    case ActionModifier.request(types.GET_TEMPLATE_AUDIT_QUESTIONS):
      return {
        ...state,
        auditTemplateQuestions: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_ALL_TEMPLATES):
      return {
        ...state,
        templates: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SEARCH_AUDITS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_QUESTION_TEMPLATES_METADATA):
      return {
        ...state,
        questionTemplatesMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_TEMPLATES_METADATA):
      return {
        ...state,
        templatesMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_AUDIT):
      return {
        ...state,
        auditDetails: {
          loading: true,
          data: null,
        },
        currentAuditIterationId: ''
      };
    case ActionModifier.request(types.GET_AUDIT_ITERATIONS):
      return {
        ...state,
        auditIterations: {
          data: state.auditIterations ? state.auditIterations.data : null,
          loading: true,
        },
      };
    case ActionModifier.request(types.SAVE_AUDIT):
    case ActionModifier.request(types.UPDATE_AUDIT):
    case ActionModifier.request(types.PATCH_TEMPLATE):
      return {
        ...state,
        auditDetails: {
          saving: true,
          data: (state.auditDetails
            ? state.auditDetails.data
            : null) as Audit,
        },
      };
    case ActionModifier.request(types.SAVE_AUDIT_ITERATION):
    case ActionModifier.request(types.UPDATE_AUDIT_ITERATION):
    case ActionModifier.request(types.DELETE_AUDIT_ITERATION):
      return {
        ...state,
        auditIterations: {
          saving: true,
          data: (state.auditIterations ? state.auditIterations.data : null) as AuditIteration[],
        },
      };
    case ActionModifier.request(types.GET_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SAVE_QUESTION_TEMPLATE):
    case ActionModifier.request(types.UPDATE_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          saving: true,
          data: (state.questionTemplateDetails ? state.questionTemplateDetails.data : null) as QuestionTemplate,
        },
      };
    case ActionModifier.request(types.GET_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SAVE_TEMPLATE):
    case ActionModifier.request(types.UPDATE_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          saving: true,
          data: (state.templateDetails ? state.templateDetails.data : null) as AuditTaxonomyTemplate,
        },
      };
    case ActionModifier.request(types.SAVE_AUDIT_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_AUDIT_ATTACHMENT):
    case ActionModifier.request(types.DELETE_AUDIT_ATTACHMENT):
      return {
        ...state,
        auditDetails: {
          loading: true,
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit,
        },
      };
    case ActionModifier.request(types.GET_AUDIT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_AUDIT_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.UPDATE_AUDIT_QUESTION):
      return {
        ...state,
        loadingItems: true,
        auditDetails: {
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit
        },
      };
    case ActionModifier.request(types.SAVE_ALLOCATION):
      return {
        ...state,
        loadingItems: true,
        auditDetails: {
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit
        },
      };
    case ActionModifier.request(types.DELETE_AUDITS):
      return {
        ...state,
        audits: {
          loading: true,
          data: (state.audits ? state.audits.data : null) as Audit[],
        },
      };
    case ActionModifier.request(types.DELETE_QUESTION_TEMPLATES):
      return {
        ...state,
        questionTemplates: {
          loading: true,
          data: (state.questionTemplates ? state.questionTemplates.data : null) as QuestionTemplate[],
        },
      };
    case ActionModifier.request(types.DELETE_TEMPLATES):
      return {
        ...state,
        templates: {
          loading: true,
          data: (state.templates ? state.templates.data : null) as AuditTaxonomyTemplate[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_AUDITS):
      return {
        ...state,
        audits: {
          loading: false,
          data: action.payload as Audit[],
        },
      };
    case ActionModifier.success(types.GET_ALL_QUESTION_TEMPLATES):
      return {
        ...state,
        questionTemplates: {
          loading: false,
          data: action.loadMore ? [...state.questionTemplates!.data, ...(action.payload as QuestionTemplate[])] : action.payload as QuestionTemplate[],
        },
      };
    case ActionModifier.success(types.GET_TEMPLATE_AUDIT_QUESTIONS):
      return {
        ...state,
        auditTemplateQuestions: {
          loading: false,
          data: action.payload as QuestionTemplate[],
        },
      };
    case ActionModifier.success(types.GET_ALL_TEMPLATES):
      return {
        ...state,
        templates: {
          loading: false,
          data: action.payload as AuditTaxonomyTemplate[],
        },
      };
    case ActionModifier.success(types.SEARCH_AUDITS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as Audit[],
        },
      };
    case ActionModifier.success(types.GET_QUESTION_TEMPLATES_METADATA):
      return {
        ...state,
        questionTemplatesMetadata: {
          loading: false,
          data: action.payload as QuestionTemplateMetadata,
        },
      };
    case ActionModifier.success(types.GET_TEMPLATES_METADATA):
      return {
        ...state,
        templatesMetadata: {
          loading: false,
          data: action.payload as AuditTaxonomyTemplateMetadata,
        },
      };
    case ActionModifier.success(types.GET_AUDIT):
      const audit = action.payload as Audit;
      return {
        ...state,
        auditDetails: {
          loading: false,
          data: audit
        },
        currentAuditIterationId: audit.auditIterations ? audit.auditIterations[audit.auditIterations.length - 1].id : ''
      };
    case ActionModifier.success(types.GET_AUDIT_ITERATIONS):
      return {
        ...state,
        auditIterations: {
          loading: false,
          data: action.payload as AuditIteration[],
        },
      };
    case ActionModifier.success(types.SAVE_AUDIT):
    case ActionModifier.success(types.UPDATE_AUDIT):
    case ActionModifier.success(types.PATCH_TEMPLATE):
      return {
        ...state,
        auditDetails: {
          saving: false,
          data: (state.auditDetails
            ? state.auditDetails.data
            : null) as Audit,
        },
      };
    case ActionModifier.success(types.SAVE_AUDIT_ITERATION):
    case ActionModifier.success(types.UPDATE_AUDIT_ITERATION):
      return {
        ...state,
        auditIterations: {
          saving: false,
          data: (state.auditIterations ? state.auditIterations.data : null) as AuditIteration[],
        },
      };
    case ActionModifier.success(types.DELETE_AUDIT_ITERATION):
      const idAuditIteration: Guid = action.payload as any;
      return {
        ...state,
        loading: false,
        auditIterations: {
          loading: false,
          data: state.auditIterations?.data?.filter((e) => e.id !== idAuditIteration) as AuditIteration[]
        },
      };
    case ActionModifier.success(types.GET_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          loading: false,
          data: action.payload as QuestionTemplate,
        },
      };
    case ActionModifier.success(types.SAVE_QUESTION_TEMPLATE):
    case ActionModifier.success(types.UPDATE_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          saving: false,
          data: (state.questionTemplateDetails ? state.questionTemplateDetails.data : null) as QuestionTemplate,
        },
      };
    case ActionModifier.success(types.GET_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          loading: false,
          data: action.payload as AuditTaxonomyTemplate,
        },
      };
    case ActionModifier.success(types.SAVE_TEMPLATE):
    case ActionModifier.success(types.UPDATE_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          saving: false,
          data: (state.templateDetails ? state.templateDetails.data : null) as AuditTaxonomyTemplate,
        },
      };
    case ActionModifier.success(types.DELETE_AUDITS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.audits?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        audits: {
          loading: false,
          data: newData as Audit[],
        },
      };
    case ActionModifier.success(types.DELETE_QUESTION_TEMPLATES):
      const questionTemplatesIds: Guid[] = action.payload as Guid[];
      const newQuestionTemplatesData = state.questionTemplates?.data?.filter(
        (e) => !questionTemplatesIds.includes(e.id as any)
      );
      return {
        ...state,
        questionTemplates: {
          loading: false,
          data: newQuestionTemplatesData as QuestionTemplate[],
        },
      };
    case ActionModifier.success(types.DELETE_TEMPLATES):
      const templatesIds: Guid[] = action.payload as Guid[];
      const newTemplatesData = state.templates?.data?.filter(
        (e) => !templatesIds.includes(e.id as any)
      );
      return {
        ...state,
        templates: {
          loading: false,
          data: newTemplatesData as AuditTaxonomyTemplate[],
        },
      };
    case ActionModifier.success(types.GET_AUDIT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: false,
          data: action.payload as IAttachmentToken,
        },
      };
    case ActionModifier.success(types.DELETE_AUDIT_ATTACHMENT):
      const idAuditAttachment: Guid = action.payload as any;
      return {
        ...state,
        loading: false,
        attachments: {
          loading: false,
          data: state.attachments?.data?.filter((e) => e.id !== idAuditAttachment) as IAttachments[]
        },
      };
    case ActionModifier.success(types.SAVE_AUDIT_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_AUDIT_ATTACHMENT):
      return {
        ...state,
        auditDetails: {
          loading: false,
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit,
        },
      };
    case ActionModifier.success(types.GET_AUDIT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (event1, event2) =>
              event2.eventTime.localeCompare(event1.eventTime)),
        },
      };
    case ActionModifier.success(types.UPDATE_AUDIT_QUESTION):
      return {
        ...state,
        loadingItems: false,
        auditDetails: {
          data: (state.auditDetails ? {
            ...state.auditDetails.data,
            auditTaxonomy: {
              ...state.auditDetails.data?.auditTaxonomy,
              auditTaxonomyItems: action.payload as AuditTaxonomyItem[]
            }
          } : null) as Audit
        },
      };
    case ActionModifier.success(types.SAVE_ALLOCATION):
      return {
        ...state,
        loadingItems: false,
        auditDetails: {
          data: (state.auditDetails ? {
            ...state.auditDetails.data,
            auditTaxonomy: {
              ...state.auditDetails.data?.auditTaxonomy,
              auditTaxonomyItems: action.payload as AuditTaxonomyItem[]
            }
          } : null) as Audit
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_AUDITS):
      return {
        ...state,
        audits: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ALL_QUESTION_TEMPLATES):
      return {
        ...state,
        questionTemplates: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_TEMPLATE_AUDIT_QUESTIONS):
      return {
        ...state,
        auditTemplateQuestions: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ALL_TEMPLATES):
      return {
        ...state,
        templates: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_AUDITS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_QUESTION_TEMPLATES_METADATA):
      return {
        ...state,
        questionTemplatesMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_TEMPLATES_METADATA):
      return {
        ...state,
        templatesMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_AUDIT):
      return {
        ...state,
        auditDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
        currentAuditIterationId: ''
      };
    case ActionModifier.failure(types.GET_AUDIT_ITERATIONS):
      return {
        ...state,
        auditIterations: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_AUDIT):
    case ActionModifier.failure(types.UPDATE_AUDIT):
    case ActionModifier.failure(types.PATCH_TEMPLATE):
      return {
        ...state,
        auditDetails: {
          saving: false,
          data: (state.auditDetails
            ? state.auditDetails.data
            : null) as Audit,
        },
      };
    case ActionModifier.failure(types.SAVE_AUDIT_ITERATION):
    case ActionModifier.failure(types.UPDATE_AUDIT_ITERATION):
      return {
        ...state,
        auditIterations: {
          saving: false,
          data: (state.auditIterations ? state.auditIterations.data : null) as AuditIteration[],
        },
      };
    case ActionModifier.failure(types.DELETE_AUDIT_ITERATION):
      return {
        ...state,
        auditIterations: {
          loading: false,
          data: (state.auditIterations ? state.auditIterations.data : null) as AuditIteration[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_QUESTION_TEMPLATE):
    case ActionModifier.failure(types.UPDATE_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          saving: false,
          data: (state.questionTemplateDetails
            ? state.questionTemplateDetails.data
            : null) as QuestionTemplate,
        },
      };
    case ActionModifier.failure(types.GET_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_TEMPLATE):
    case ActionModifier.failure(types.UPDATE_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          saving: false,
          data: (state.templateDetails ? state.templateDetails.data : null) as AuditTaxonomyTemplate,
        },
      };
    case ActionModifier.failure(types.DELETE_AUDITS):
      return {
        ...state,
        audits: {
          loading: false,
          data: (state.audits ? state.audits.data : null) as Audit[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_QUESTION_TEMPLATES):
      return {
        ...state,
        questionTemplates: {
          loading: false,
          data: (state.questionTemplates ? state.questionTemplates.data : null) as QuestionTemplate[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_TEMPLATES):
      return {
        ...state,
        templates: {
          loading: false,
          data: (state.templates ? state.templates.data : null) as AuditTaxonomyTemplate[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_AUDIT_ATTACHMENT_TOKEN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_AUDIT_ATTACHMENT):
      return {
        ...state,
        auditDetails: {
          loading: false,
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_AUDIT_ATTACHMENT):
      return {
        ...state,
        auditDetails: {
          loading: false,
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_AUDIT_ATTACHMENT):
      return {
        ...state,
        auditDetails: {
          loading: false,
          data: (state.auditDetails ? state.auditDetails.data : null) as Audit,
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_AUDIT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.UPDATE_AUDIT_QUESTION):
      return {
        ...state,
        loadingItems: false,
        auditDetails: {
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_ALLOCATION):
      return {
        ...state,
        loadingItems: false,
        auditDetails: {
          data: null,
          error: action.payload as any,
        },
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_AUDIT):
      return {
        ...state,
        auditDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_QUESTION_TEMPLATE):
      return {
        ...state,
        questionTemplateDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_TEMPLATE):
      return {
        ...state,
        templateDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_AUDIT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default auditReducer;
