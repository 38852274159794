import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          color: "#00549e",
        },
      },
    },

    MuiTextField: {
      root: {
        backgroundColor: "#fff",
        fontSize: 16,

      },
    },

    MuiFormHelperText: {
      root: {
        backgroundColor: "unset",
      },
    },

    MuiSelect: {
      root: {
        backgroundColor: "#fff",
        fontSize: 16,
      },
    },

    MuiList: {
      root: {
        backgroundColor: '#efefef'
      }
    },

    MuiTabs: {
      scrollButtons: {
        "&.Mui-disabled": {
          opacity: 0.3
        },
      },
    }
  },
});

export default theme;
