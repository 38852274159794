import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {Group} from "../../domain/groups/Group";

type GroupReducerPayload =
  | Group
  | Group[]
  | Guid
  | Guid[]
  | null;

export interface IGroupAction
  extends IDispatchBaseActionResult<GroupReducerPayload, any> {}

export interface IGroupState extends IAuditable {
  loading: boolean;
  groupDetails: IAuditableResult<Group, any> | null;
  groups: IAuditableResult<Group[], any> | null;
  error: any | null;
}

const initialState: IGroupState = {
  loading: false,
  groupDetails: new AuditableResult<Group, any>(),
  groups: new AuditableResult<Group[], any>(),
  error: null,
};

const groupReducer = (
  state = initialState,
  action: IGroupAction
): IGroupState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_GROUPS):
      return {
        ...state,
        groups: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_GROUP):
      return {
        ...state,
        groupDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SAVE_GROUP):
    case ActionModifier.request(types.UPDATE_GROUP):
      return {
        ...state,
        groupDetails: {
          saving: true,
          data: (state.groupDetails ? state.groupDetails.data : null) as Group,
        },
      };
    case ActionModifier.request(types.DELETE_GROUPS):
      return {
        ...state,
        groups: {
          loading: true,
          data: (state.groups ? state.groups.data : null) as Group[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_GROUPS):
      return {
        ...state,
        groups: {
          loading: false,
          data: action.payload as Group[],
        },
      };
    case ActionModifier.success(types.GET_GROUP):
      return {
        ...state,
        groupDetails: {
          loading: false,
          data: action.payload as Group,
        },
      };
    case ActionModifier.success(types.SAVE_GROUP):
    case ActionModifier.success(types.UPDATE_GROUP):
      return {
        ...state,
        groupDetails: {
          saving: false,
          data: (state.groupDetails ? state.groupDetails.data : null) as Group,
        },
      };
    case ActionModifier.success(types.DELETE_GROUPS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.groups?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        groups: {
          loading: false,
          data: newData as Group[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_GROUPS):
      return {
        ...state,
        groups: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_GROUP):
      return {
        ...state,
        groupDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_GROUP):
    case ActionModifier.failure(types.UPDATE_GROUP):
      return {
        ...state,
        groupDetails: {
          saving: false,
          data: (state.groupDetails ? state.groupDetails.data : null) as Group,
        },
      };
    case ActionModifier.failure(types.DELETE_GROUPS):
      return {
        ...state,
        groups: {
          loading: false,
          data: (state.groups ? state.groups.data : null) as Group[],
        },
        error: action.payload as any,
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_GROUP):
      return {
        ...state,
        groupDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default groupReducer;
