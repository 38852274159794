export const GET_COMPLAINT = "complaints/GET_COMPLAINT";
export const GET_COMPLAINT_REFRESH_CAPAS = "complaints/GET_COMPLAINT_REFRESH_CAPAS";
export const SEARCH_COMPLAINTS = "complaints/SEARCH_COMPLAINTS";
export const DELETE_COMPLAINTS = "complaints/DELETE_COMPLAINTS";
export const SAVE_COMPLAINT = "complaints/SAVE_COMPLAINT";
export const UPDATE_COMPLAINT = "complaints/UPDATE_COMPLAINT";
export const PATCH_COMPLAINT = "complaints/PATCH_COMPLAINT";
export const GET_ALL_COMPLAINTS = "complaints/GET_ALL_COMPLAINTS";
export const GET_COMPLAINTS_METADATA = "complaints/GET_COMPLAINTS_METADATA";
export const GET_NON_CONFORMITIES_METADATA = "nonConformities/GET_NON_CONFORMITIES_METADATA";
export const GET_SAFETY_INCIDENTS_METADATA = "safetyIncidents/GET_SAFETY_INCIDENTS_METADATA";
export const GET_CAPAS_METADATA = "capas/GET_ACTIONS_METADATA";

export const GET_COMPLAINT_PRODUCTS_RETURNS = "complaints/GET_COMPLAINT_PRODUCTS_RETURNS";
export const ADD_COMPLAINT_PRODUCTS_RETURNS = "complaints/ADD_COMPLAINT_PRODUCTS_RETURNS";
export const DELETE_COMPLAINT_PRODUCTS_RETURN = "complaints/DELETE_COMPLAINT_PRODUCTS_RETURN";
export const GET_COMPLAINT_ATTACHMENTS = "complaints/GET_COMPLAINT_ATTACHMENTS";
export const GET_COMPLAINT_ATTACHMENT_TOKEN = "complaints/GET_COMPLAINT_ATTACHMENT_TOKEN";
export const SAVE_COMPLAINT_ATTACHMENT = "complaints/SAVE_COMPLAINT_ATTACHMENT";
export const UPDATE_COMPLAINT_ATTACHMENT = "complaints/UPDATE_COMPLAINT_ATTACHMENT";
export const DELETE_COMPLAINT_ATTACHMENT = "complaints/DELETE_COMPLAINT_ATTACHMENT";
export const UPDATE_COMPLAINT_PRODUCTS_RETURNS = "complaints/UPDATE_COMPLAINT_PRODUCTS_RETURNS";
export const GET_COMPLAINT_EVENTS = "complaints/GET_COMPLAINT_EVENTS";
export const GET_COMPLAINT_WORKFLOWS = "complaints/GET_COMPLAINT_TASKS";

export const GET_ALL_NON_CONFORMITIES =
  "nonConformities/GET_ALL_NON_CONFORMITIES";
export const SEARCH_NON_CONFORMITIES =
  "nonConformities/SEARCH_NON_CONFORMITIES";
export const GET_NON_CONFORMITY = "nonConformities/GET_NON_CONFORMITY";
export const GET_NON_CONFORMITY_REFRESH_CAPAS = "nonConformities/GET_NON_CONFORMITY_REFRESH_CAPAS";
export const SAVE_NON_CONFORMITY =
  "nonConformities/SAVE_NON_CONFORMITY";
export const UPDATE_NON_CONFORMITY =
  "nonConformities/UPDATE_NON_CONFORMITY";
export const DELETE_NON_CONFORMITIES =
  "nonConformities/DELETE_NON_CONFORMITIES";
export const GET_NON_CONFORMITY_ATTACHMENT_TOKEN =
  "nonConformities/GET_NON_CONFORMITY_ATTACHMENT_TOKEN";
export const SAVE_NON_CONFORMITY_ATTACHMENT =
  "nonConformities/SAVE_NON_CONFORMITY_ATTACHMENT";
export const UPDATE_NON_CONFORMITY_ATTACHMENT =
  "nonConformities/UPDATE_NON_CONFORMITY_ATTACHMENT";
export const DELETE_NON_CONFORMITY_ATTACHMENT =
  "nonConformities/DELETE_NON_CONFORMITY_ATTACHMENT";
export const GET_NON_CONFORMITY_EVENTS =
  "nonConformities/GET_NON_CONFORMITY_EVENTS";
export const GET_NON_CONFORMITY_WORKFLOWS =
  "nonConformities/GET_NON_CONFORMITY_WORKFLOWS"
export const PATCH_NON_CONFORMITY = "nonConformities/PATCH_NON_CONFORMITY"

export const GET_ALL_SAFETY_INCIDENTS =
  "safetyIncidents/GET_ALL_SAFETY_INCIDENTS";
export const SEARCH_SAFETY_INCIDENTS =
  "safetyIncidents/SEARCH_SAFETY_INCIDENTS";
export const GET_SAFETY_INCIDENT = "safetyIncidents/GET_SAFETY_INCIDENT";
export const GET_SAFETY_INCIDENT_REFRESH_CAPAS = "safetyIncidents/GET_SAFETY_INCIDENT_REFRESH_CAPAS";
export const SAVE_SAFETY_INCIDENT =
  "safetyIncidents/SAVE_SAFETY_INCIDENT";
export const UPDATE_SAFETY_INCIDENT =
  "safetyIncidents/UPDATE_SAFETY_INCIDENT";
export const DELETE_SAFETY_INCIDENTS =
  "safetyIncidents/DELETE_SAFETY_INCIDENTS";
export const GET_SAFETY_INCIDENT_ATTACHMENT_TOKEN =
  "safetyIncidents/GET_SAFETY_INCIDENT_ATTACHMENT_TOKEN";
export const SAVE_SAFETY_INCIDENT_ATTACHMENT =
  "safetyIncidents/SAVE_SAFETY_INCIDENT_ATTACHMENT";
export const UPDATE_SAFETY_INCIDENT_ATTACHMENT =
  "safetyIncidents/UPDATE_SAFETY_INCIDENT_ATTACHMENT";
export const DELETE_SAFETY_INCIDENT_ATTACHMENT =
  "safetyIncidents/DELETE_SAFETY_INCIDENT_ATTACHMENT";
export const GET_SAFETY_INCIDENT_EVENTS =
  "safetyIncidents/GET_SAFETY_INCIDENT_EVENTS";
export const GET_SAFETY_INCIDENT_WORKFLOWS = "safetyIncidents/GET_SAFETY_INCIDENT_WORKFLOWS"

export const GET_ALL_CAPAS = "capas/GET_ALL_ACTIONS";
export const SEARCH_CAPAS = "capas/SEARCH_ACTIONS";
export const GET_CAPA = "capas/GET_ACTION";
export const SAVE_CAPA = "capas/SAVE_ACTION";
export const UPDATE_CAPA = "capas/UPDATE_ACTION";
export const DELETE_CAPAS = "capas/DELETE_ACTIONS";
export const GET_CAPA_ATTACHMENT_TOKEN = "capas/GET_ACTION_ATTACHMENT_TOKEN";
export const SAVE_CAPA_ATTACHMENT = "capas/SAVE_ACTION_ATTACHMENT";
export const UPDATE_CAPA_ATTACHMENT = "capas/UPDATE_ACTION_ATTACHMENT";
export const DELETE_CAPA_ATTACHMENT = "capas/DELETE_ACTION_ATTACHMENT";
export const GET_CAPA_EVENTS = "capas/GET_ACTION_EVENTS";
export const GET_CAPA_WORKFLOWS = "capas/GET_ACTION_WORKFLOWS";
export const GET_CAPA_TASK = "capas/GET_ACTION_TASK";

export const GET_ALL_ELECTRICITY_ITEMS = "electricity/GET_ALL_ELECTRICITY_ITEMS";
export const GET_ELECTRICITY_ITEM = "electricity/GET_ELECTRICITY_ITEM";
export const SAVE_ELECTRICITY_ITEM = "electricity/SAVE_ELECTRICITY_ITEM";
export const UPDATE_ELECTRICITY_ITEM = "electricity/UPDATE_ELECTRICITY_ITEM";
export const DELETE_ELECTRICITY_ITEM = "electricity/DELETE_ELECTRICITY_ITEM";

export const GET_ALL_WASTE_ITEMS = "waste/GET_ALL_WASTE_ITEMS";
export const GET_WASTE_ITEM = "waste/GET_WASTE_ITEM";
export const GET_WASTE_METADATA = "waste/GET_WASTE_METADATA";
export const SAVE_WASTE_ITEM = "waste/SAVE_WASTE_ITEM";
export const UPDATE_WASTE_ITEM = "waste/UPDATE_WASTE_ITEM";
export const DELETE_WASTE_ITEM = "waste/DELETE_WASTE_ITEM";

export const GET_ALL_DOCUMENTS = "documents/GET_ALL_DOCUMENTS";
export const GET_DOCUMENT = "documents/GET_DOCUMENT";
export const GET_DOCUMENT_TOKEN = "documents/GET_DOCUMENT_TOKEN";
export const GET_DOCUMENTS_METADATA = "documents/GET_DOCUMENTS_METADATA";
export const GET_DOCUMENT_EVENTS = "documents/GET_DOCUMENT_EVENTS";
export const GET_DOCUMENT_VERSIONS = "documents/GET_DOCUMENT_VERSIONS";
export const GET_DOCUMENT_WORKFLOWS = "documents/GET_DOCUMENT_TASKS";
export const SAVE_DOCUMENT = "documents/SAVE_DOCUMENT";
export const UPDATE_DOCUMENT = "documents/UPDATE_DOCUMENT";
export const SAVE_MINOR_VERSION = "documents/SAVE_MINOR_VERSION";
export const DELETE_DOCUMENT = "documents/DELETE_DOCUMENT";
export const START_DOCUMENT_APPROVAL = "documents/START_DOCUMENT_APPROVAL";
export const CREATE_COLLABORATION_DOCUMENT = "documents/CREATE_COLLABORATION_DOCUMENT";
export const SEARCH_DOCUMENTS = "documents/SEARCH_DOCUMENTS";

export const GET_ALL_AUDITS = "audits/GET_ALL_AUDITS";
export const GET_ALL_QUESTION_TEMPLATES = "audits/GET_ALL_QUESTIONS";
export const GET_TEMPLATE_AUDIT_QUESTIONS = "audits/GET_TEMPLATE_AUDIT_QUESTIONS";
export const GET_ALL_TEMPLATES = "audits/GET_ALL_TEMPLATES";
export const GET_QUESTION_TEMPLATES_METADATA = "audits/GET_QUESTION_TEMPLATES_METADATA";
export const GET_TEMPLATES_METADATA = "audits/GET_TEMPLATES_METADATA";
export const SEARCH_AUDITS = "audits/SEARCH_AUDITS";
export const GET_AUDIT = "audits/GET_AUDIT";
export const GET_AUDIT_ITERATIONS = "audits/GET_AUDIT_ITERATIONS";
export const SAVE_AUDIT_ITERATION = "audits/SAVE_AUDIT_ITERATION";
export const UPDATE_AUDIT_ITERATION = "audits/UPDATE_AUDIT_ITERATION";
export const DELETE_AUDIT_ITERATION = "audits/DELETE_AUDIT_ITERATION";
export const GET_QUESTION_TEMPLATE = "audits/GET_QUESTION_TEMPLATE";
export const GET_TEMPLATE = "audits/GET_TEMPLATE";
export const SAVE_AUDIT = "audits/SAVE_AUDIT";
export const SAVE_QUESTION_TEMPLATE = "audits/SAVE_QUESTION_TEMPLATE";
export const SAVE_TEMPLATE = "audits/SAVE_TEMPLATE";
export const SAVE_ALLOCATION = "audits/SAVE_ALLOCATION";
export const UPDATE_AUDIT = "audits/UPDATE_AUDIT";
export const UPDATE_AUDIT_QUESTION = "audits/UPDATE_AUDIT_QUESTION";
export const UPDATE_QUESTION_TEMPLATE = "audits/UPDATE_QUESTION_TEMPLATE";
export const UPDATE_TEMPLATE = "audits/UPDATE_TEMPLATE";
export const PATCH_TEMPLATE = "audits/PATCH_TEMPLATE";
export const DELETE_AUDITS = "audits/DELETE_AUDITS";
export const DELETE_QUESTION_TEMPLATES = "audits/DELETE_QUESTION_TEMPLATES";
export const DELETE_TEMPLATES = "audits/DELETE_TEMPLATES";
export const GET_AUDIT_ATTACHMENT_TOKEN = "audits/GET_AUDIT_ATTACHMENT_TOKEN";
export const SAVE_AUDIT_ATTACHMENT = "audits/SAVE_AUDIT_ATTACHMENT";
export const UPDATE_AUDIT_ATTACHMENT = "audits/UPDATE_AUDIT_ATTACHMENT";
export const DELETE_AUDIT_ATTACHMENT = "audits/DELETE_AUDIT_ATTACHMENT";
export const DELETE_TEMPLATE_AUDIT_QUESTIONS = "audits/DELETE_TEMPLATE_AUDIT_QUESTIONS";
export const GET_AUDIT_EVENTS = "audits/GET_AUDIT_EVENTS";

export const SEARCH_RELATED_ITEMS =
  "relatedItems/SEARCH_RELATED_ITEMS";
export const SAVE_RELATED_ITEM =
  "relatedItems/SAVE_RELATED_ITEM";
export const UPDATE_RELATED_ITEM =
  "relatedItems/UPDATE_RELATED_ITEM";
export const DELETE_RELATED_ITEMS =
  "relatedItems/DELETE_RELATED_ITEMS";

export const GET_MY_NOTIFICATIONS = "notifications/GET_MY_NOTIFICATIONS";
export const GET_MY_NOTIFICATIONS_TODAY_COUNT = "notifications/GET_MY_NOTIFICATIONS_TODAY_COUNT";
export const GET_MY_TASKS = "tasks/GET_MY_TASKS";
export const GET_DASHBOARD_ACTIVE_TASKS = "tasks/GET_DASHBOARD_ACTIVE_TASKS";
export const GET_DASHBOARD_OVERDUE_TASKS = "tasks/GET_DASHBOARD_OVERDUE_TASKS";
export const GET_TASK = "tasks/GET_TASK";

export const GET_COMMON_SITES = "common/GET_COMMON_SITES";
export const GET_COMMON_METADATA = "common/GET_COMMON_METADATA";
export const GET_COMMON_DASHBOARD = "common/GET_COMMON_DASHBOARD";
export const SEARCH = "common/SEARCH";
export const SET_SEARCH_STRING = "common/SET_SEARCH_STRING";

export const GET_DATA_BY_FIELD_DEFINITION = "common/GET_DATA_BY_FIELD_DEFINITION";
export const GET_REGULAR_GROUPS = "common/GET_REGULAR_GROUPS";
export const GET_ORGANIZATIONAL_STRUCTURES = "common/GET_ORGANIZATIONAL_STRUCTURES";
export const UPDATE_TASK = "common/UPDATE_TASK";
export const DELEGATE_TASKS = "common/DELEGATE_TASKS";
export const PATCH_TASK = "common/PATCH_TASK";
export const SET_TITLE = "common/SET_TITLE";
export const SET_CUSTOMER_CONTACTS = "common/SET_CUSTOMER_CONTACTS";

export const GET_ALL_GROUPS = "config/GET_ALL_GROUPS";
export const GET_GROUP = "config/GET_GROUP";
export const SAVE_GROUP = "config/SAVE_GROUP";
export const UPDATE_GROUP = "config/UPDATE_GROUP";
export const DELETE_GROUPS = "config/DELETE_GROUPS";
export const DELETE_GROUP_USER = "config/DELETE_GROUP_USER";
export const ADD_GROUP_USERS = "config/ADD_GROUP_USERS";
export const ADD_GROUP_EXTERNAL_USERS = "config/ADD_GROUP_EXTERNAL_USERS";
export const DELETE_GROUP_EXTERNAL_USER = "config/DELETE_GROUP_EXTERNAL_USER";

export const GET_ALL_SITES = "config/GET_ALL_SITES";
export const GET_SITE = "config/GET_SITE";
export const SAVE_SITE = "config/SAVE_SITE";
export const UPDATE_SITE = "config/UPDATE_SITE";
export const DELETE_SITES = "config/DELETE_SITES";

export const GET_ACCOUNT_WITH_TEAMS = "config/GET_ACCOUNT_WITH_TEAMS";
export const GET_ALL_ACCOUNTS_WITH_TEAMS = "config/GET_ALL_ACCOUNTS_WITH_TEAMS";
export const UPDATE_ACCOUNT_WITH_TEAMS = "config/UPDATE_ACCOUNT_WITH_TEAMS";
export const SEARCH_ACCOUNTS = "config/SEARCH_ACCOUNTS";

export const GET_EQUIPMENT = "equipments/GET_EQUIPMENT";
export const SEARCH_EQUIPMENTS = "equipments/SEARCH_EQUIPMENTS";
export const DELETE_EQUIPMENTS = "equipments/DELETE_EQUIPMENTS";
export const SAVE_EQUIPMENT = "equipments/SAVE_EQUIPMENT";
export const UPDATE_EQUIPMENT = "equipments/UPDATE_EQUIPMENT";
export const GET_ALL_EQUIPMENTS = "equipments/GET_ALL_EQUIPMENTS";
export const GET_EQUIPMENTS_METADATA = "equipments/GET_EQUIPMENTS_METADATA";
export const GET_EQUIPMENT_ATTACHMENTS = "equipments/GET_EQUIPMENT_ATTACHMENTS";
export const GET_EQUIPMENT_ATTACHMENT_TOKEN = "equipments/GET_EQUIPMENT_ATTACHMENT_TOKEN";
export const SAVE_EQUIPMENT_ATTACHMENT = "equipments/SAVE_EQUIPMENT_ATTACHMENT";
export const UPDATE_EQUIPMENT_ATTACHMENT = "equipments/UPDATE_EQUIPMENT_ATTACHMENT";
export const DELETE_EQUIPMENT_ATTACHMENT = "equipments/DELETE_EQUIPMENT_ATTACHMENT";
export const GET_EQUIPMENT_EVENTS = "complaints/GET_EQUIPMENT_EVENTS";

export const GET_EQUIPMENT_CONTROL = "equipmentControls/GET_EQUIPMENT_CONTROL";
export const DELETE_EQUIPMENT_CONTROLS = "equipmentControls/DELETE_EQUIPMENT_CONTROLS";
export const SAVE_EQUIPMENT_CONTROL = "equipmentControls/SAVE_EQUIPMENT_CONTROL";
export const UPDATE_EQUIPMENT_CONTROL = "equipmentControls/UPDATE_EQUIPMENT_CONTROL";
export const GET_ALL_EQUIPMENT_CONTROLS = "equipmentControls/GET_ALL_EQUIPMENT_CONTROLS";
export const GET_EQUIPMENT_CONTROLS_METADATA = "equipmentControls/GET_EQUIPMENT_CONTROLS_METADATA";

export const GET_REFERENCE_EQUIPMENT = "equipments/GET_REFERENCE_EQUIPMENT";
export const DELETE_REFERENCE_EQUIPMENTS = "equipments/DELETE_REFERENCE_EQUIPMENTS";
export const SAVE_REFERENCE_EQUIPMENT = "equipments/SAVE_REFERENCE_EQUIPMENT";
export const UPDATE_REFERENCE_EQUIPMENT = "equipments/UPDATE_REFERENCE_EQUIPMENT";
export const GET_ALL_REFERENCE_EQUIPMENTS = "equipments/GET_REFERENCE_ALL_EQUIPMENTS";
export const GET_REFERENCE_EQUIPMENT_ATTACHMENTS = "equipments/GET_REFERENCE_EQUIPMENT_ATTACHMENTS";
export const SAVE_REFERENCE_EQUIPMENT_ATTACHMENT = "equipments/SAVE_REFERENCE_EQUIPMENT_ATTACHMENT";
export const UPDATE_REFERENCE_EQUIPMENT_ATTACHMENT = "equipments/UPDATE_REFERENCE_EQUIPMENT_ATTACHMENT";
export const DELETE_REFERENCE_EQUIPMENT_ATTACHMENT = "equipments/DELETE_REFERENCE_EQUIPMENT_ATTACHMENT";
export const GET_REFERENCE_EQUIPMENT_EVENTS = "complaints/GET_REFERENCE_EQUIPMENT_EVENTS";

export const GET_ALL_ITEM_DATAS = "management/GET_ALL_ITEM_DATAS";
export const UPDATE_ITEM_DATAS = "management/UPDATE_ITEM_DATAS";

// Users
export const GET_USER_INFO = "users/GET_USER_INFO";
export const GET_ALL_USERS = "users/GET_ALL_USERS";
export const SET_USER_SELECTED_SITE = "users/SET_USER_SELECTED_SITE";
export const GET_LOGO = "users/GET_LOGO";
export const GET_TENANT_SETTINGS = "users/GET_TENANT_SETTINGS";
export const UPDATE_ACCOUNT = "users/UPDATE_ACCOUNT";
export const GET_EXTERNAL_USERS = "users/GET_EXTERNAL_USERS";
export const GET_SITE_USERS = "users/GET_SITE_USERS";
export const GET_MY_DOCUMENTS = "users/GET_MY_DOCUMENTS";
export const GET_MY_AUDITS = "users/GET_MY_AUDITS";

// WORKFLOW
export const RESTART_WORKFLOW = "common/RESTART_WORKFLOW";
