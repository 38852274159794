import { Configuration, PopupRequest } from "@azure/msal-browser";
import config from "../config";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {        
        clientId: config.sso_clientid,
        authority: config.sso_authority,
        redirectUri: config.sso_redirecturi,
        postLogoutRedirectUri: config.sso_postlogoutredirecturi
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: config.sso_scopes
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
