import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {AccountWithTeams} from "../../domain/account-with-teams/AccountWithTeams";

type AccountWithTeamsReducerPayload =
  | AccountWithTeams
  | AccountWithTeams[]
  | Guid
  | null;

export interface IAccountWithTeamsAction
  extends IDispatchBaseActionResult<AccountWithTeamsReducerPayload, any> {}

export interface IAccountWithTeamsState extends IAuditable {
  loading: boolean;
  accountWithTeamsDetails: IAuditableResult<AccountWithTeams, any> | null;
  accountsWithTeams: IAuditableResult<AccountWithTeams[], any> | null;
  searchResults: IAuditableResult<AccountWithTeams[], any> | null;
  error: any | null;
}

const initialState: IAccountWithTeamsState = {
  loading: false,
  accountWithTeamsDetails: new AuditableResult<AccountWithTeams, any>(),
  accountsWithTeams: new AuditableResult<AccountWithTeams[], any>(),
  searchResults: new AuditableResult<AccountWithTeams[], any>(),
  error: null,
};

const accountWithTeamsReducer = (
  state = initialState,
  action: IAccountWithTeamsAction
): IAccountWithTeamsState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_ACCOUNTS_WITH_TEAMS):
      return {
        ...state,
        loading: true,
        accountsWithTeams: {
          data: null,
        },
      };
    case ActionModifier.request(types.GET_ACCOUNT_WITH_TEAMS):
      return {
        ...state,
        accountWithTeamsDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.UPDATE_ACCOUNT_WITH_TEAMS):
      return {
        ...state,
        accountWithTeamsDetails: {
          saving: true,
          data: (state.accountWithTeamsDetails ? state.accountWithTeamsDetails.data : null) as AccountWithTeams,
        },
      };
    case ActionModifier.request(types.SEARCH_ACCOUNTS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_ACCOUNTS_WITH_TEAMS):
      return {
        ...state,
        loading: false,
        accountsWithTeams: {
          data: action.payload as AccountWithTeams[],
        },
      };
    case ActionModifier.success(types.UPDATE_ACCOUNT_WITH_TEAMS):
      return {
        ...state,
        accountWithTeamsDetails: {
          saving: false,
          data: (state.accountWithTeamsDetails ? state.accountWithTeamsDetails.data : null) as AccountWithTeams,
        },
      };
    case ActionModifier.success(types.SEARCH_ACCOUNTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as AccountWithTeams[],
        },
      };
    case ActionModifier.success(types.GET_ACCOUNT_WITH_TEAMS):
      return {
        ...state,
        accountWithTeamsDetails: {
          loading: false,
          data: action.payload as AccountWithTeams,
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_ACCOUNTS_WITH_TEAMS):
      return {
        ...state,
        loading: false,
        accountsWithTeams: {
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.UPDATE_SITE):
      return {
        ...state,
        accountWithTeamsDetails: {
          saving: false,
          data: (state.accountWithTeamsDetails ? state.accountWithTeamsDetails.data : null) as AccountWithTeams,
        },
      };
    case ActionModifier.failure(types.SEARCH_ACCOUNTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ACCOUNT_WITH_TEAMS):
      return {
        ...state,
        accountWithTeamsDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_ACCOUNT_WITH_TEAMS):
      return {
        ...state,
        accountWithTeamsDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default accountWithTeamsReducer;
