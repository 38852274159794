import {Ability, AbilityBuilder} from '@casl/ability';
import {AbilityActions} from "./enums/AbilityActions";
import {Entity} from "../domain/common/common";

const checkAbilities = (type) => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  cannot(AbilityActions.View, Entity.Sites);
  cannot(AbilityActions.Create, Entity.Sites);
  cannot(AbilityActions.Edit, Entity.Sites);
  cannot(AbilityActions.Delete, Entity.Sites);
  cannot(AbilityActions.Edit, Entity.Workflow);
  cannot(AbilityActions.Edit, Entity.Draft);

  switch (type) {
    case 'Stakeholder':
      can(AbilityActions.View, Entity.Complaint);
      cannot(AbilityActions.Create, Entity.Complaint);
      cannot(AbilityActions.Edit, Entity.Complaint);
      cannot(AbilityActions.Delete, Entity.Complaint);

      cannot(AbilityActions.Create, Entity.Attachments);
      cannot(AbilityActions.Edit, Entity.Attachments);
      cannot(AbilityActions.Delete, Entity.Attachments);

      cannot(AbilityActions.Create, Entity.RelatedItems);
      cannot(AbilityActions.Edit, Entity.RelatedItems);
      cannot(AbilityActions.Delete, Entity.RelatedItems);

      cannot(AbilityActions.Create, Entity.CAPA);
      cannot(AbilityActions.CreateInTask, Entity.CAPA);

      cannot(AbilityActions.Create, Entity.ProductReturns);
      cannot(AbilityActions.Edit, Entity.ProductReturns);
      cannot(AbilityActions.Delete, Entity.ProductReturns);
      cannot(AbilityActions.CreateInTask, Entity.ProductReturns);
      cannot(AbilityActions.EditInTask, Entity.ProductReturns);
      cannot(AbilityActions.DeleteInTask, Entity.ProductReturns);

      can(AbilityActions.View, Entity.Task);
      cannot(AbilityActions.Edit, Entity.Task);

      can(AbilityActions.View, Entity.NonConformity);
      cannot(AbilityActions.Create, Entity.NonConformity);
      cannot(AbilityActions.Edit, Entity.NonConformity);
      cannot(AbilityActions.Delete, Entity.NonConformity);
      cannot(AbilityActions.EditInTask, Entity.NonConformity);

      can(AbilityActions.View, Entity.SafetyIncident);
      cannot(AbilityActions.Create, Entity.SafetyIncident);
      cannot(AbilityActions.Edit, Entity.SafetyIncident);
      cannot(AbilityActions.Delete, Entity.SafetyIncident);
      cannot(AbilityActions.EditInTask, Entity.SafetyIncident);

      can(AbilityActions.View, Entity.CAPA);
      cannot(AbilityActions.Create, Entity.CAPA);
      cannot(AbilityActions.Edit, Entity.CAPA);
      cannot(AbilityActions.Delete, Entity.CAPA);
      cannot(AbilityActions.EditInTask, Entity.CAPA);

      cannot(AbilityActions.View, Entity.Group);
      cannot(AbilityActions.View, Entity.User);
      cannot(AbilityActions.Create, Entity.Group);
      cannot(AbilityActions.Create, Entity.User);
      cannot(AbilityActions.Edit, Entity.Group);
      cannot(AbilityActions.Edit, Entity.User);
      cannot(AbilityActions.Delete, Entity.Group);
      cannot(AbilityActions.Delete, Entity.User);

      cannot(AbilityActions.View, Entity.AccountWithTeams);
      cannot(AbilityActions.View, Entity.Translations);

      cannot(AbilityActions.View, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.Create, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.Edit, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.Delete, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.View, Entity.AuditTemplate);
      cannot(AbilityActions.Create, Entity.AuditTemplate);
      cannot(AbilityActions.Edit, Entity.AuditTemplate);
      cannot(AbilityActions.Delete, Entity.AuditTemplate);
      cannot(AbilityActions.View, Entity.Audit);
      cannot(AbilityActions.Create, Entity.Audit);
      cannot(AbilityActions.Edit, Entity.Audit);
      cannot(AbilityActions.Delete, Entity.Audit);
      cannot(AbilityActions.AnswerAudit, Entity.Audit);

      cannot(AbilityActions.View, Entity.Documents);
      cannot(AbilityActions.Create, Entity.Documents);
      cannot(AbilityActions.Edit, Entity.Documents);
      cannot(AbilityActions.Delete, Entity.Documents);
      cannot(AbilityActions.StartApproval, Entity.Documents);

      cannot(AbilityActions.View, Entity.Electricity);
      cannot(AbilityActions.Create, Entity.Electricity);
      cannot(AbilityActions.Edit, Entity.Electricity);
      cannot(AbilityActions.Delete, Entity.Electricity);

      cannot(AbilityActions.View, Entity.Waste);
      cannot(AbilityActions.Create, Entity.Waste);
      cannot(AbilityActions.Edit, Entity.Waste);
      cannot(AbilityActions.Delete, Entity.Waste);

      can(AbilityActions.View, Entity.Equipment);
      cannot(AbilityActions.Create, Entity.Equipment);
      cannot(AbilityActions.Edit, Entity.Equipment);
      cannot(AbilityActions.Delete, Entity.Equipment);

      can(AbilityActions.View, Entity.EquipmentControl);
      cannot(AbilityActions.Create, Entity.EquipmentControl);
      cannot(AbilityActions.Edit, Entity.EquipmentControl);
      cannot(AbilityActions.Delete, Entity.EquipmentControl);

      can(AbilityActions.View, Entity.ReferenceEquipment);
      cannot(AbilityActions.Create, Entity.ReferenceEquipment);
      cannot(AbilityActions.Edit, Entity.ReferenceEquipment);
      cannot(AbilityActions.Delete, Entity.ReferenceEquipment);

      can(AbilityActions.View, Entity.Notification);

      break;
    case 'User':
      can(AbilityActions.View, Entity.Complaint);
      can(AbilityActions.Create, Entity.Complaint);
      cannot(AbilityActions.Edit, Entity.Complaint);
      cannot(AbilityActions.Delete, Entity.Complaint);
      can(AbilityActions.EditInTask, Entity.Complaint);

      can(AbilityActions.Create, Entity.Attachments);
      can(AbilityActions.Edit, Entity.Attachments);
      can(AbilityActions.Delete, Entity.Attachments);

      can(AbilityActions.Create, Entity.RelatedItems);
      can(AbilityActions.Edit, Entity.RelatedItems);
      can(AbilityActions.Delete, Entity.RelatedItems);

      cannot(AbilityActions.Create, Entity.ProductReturns);
      cannot(AbilityActions.Edit, Entity.ProductReturns);
      cannot(AbilityActions.Delete, Entity.ProductReturns);

      can(AbilityActions.CreateInTask, Entity.ProductReturns);
      can(AbilityActions.EditInTask, Entity.ProductReturns);
      can(AbilityActions.DeleteInTask, Entity.ProductReturns);

      can(AbilityActions.View, Entity.Task);
      can(AbilityActions.Edit, Entity.Task);

      can(AbilityActions.View, Entity.NonConformity);
      can(AbilityActions.Create, Entity.NonConformity);
      cannot(AbilityActions.Edit, Entity.NonConformity);
      cannot(AbilityActions.Delete, Entity.NonConformity);
      can(AbilityActions.EditInTask, Entity.NonConformity);

      can(AbilityActions.View, Entity.SafetyIncident);
      can(AbilityActions.Create, Entity.SafetyIncident);
      cannot(AbilityActions.Edit, Entity.SafetyIncident);
      cannot(AbilityActions.Delete, Entity.SafetyIncident);
      can(AbilityActions.EditInTask, Entity.SafetyIncident);

      can(AbilityActions.View, Entity.CAPA);
      can(AbilityActions.Create, Entity.CAPA);
      cannot(AbilityActions.Edit, Entity.CAPA);
      cannot(AbilityActions.Delete, Entity.CAPA);

      can(AbilityActions.CreateInTask, Entity.CAPA);
      can(AbilityActions.EditInTask, Entity.CAPA);

      cannot(AbilityActions.View, Entity.Group);
      cannot(AbilityActions.View, Entity.User);
      cannot(AbilityActions.Create, Entity.Group);
      cannot(AbilityActions.Create, Entity.User);
      cannot(AbilityActions.Edit, Entity.Group);
      cannot(AbilityActions.Edit, Entity.User);
      cannot(AbilityActions.Delete, Entity.Group);
      cannot(AbilityActions.Delete, Entity.User);

      cannot(AbilityActions.View, Entity.AccountWithTeams);
      cannot(AbilityActions.View, Entity.Translations);

      cannot(AbilityActions.View, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.Create, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.Edit, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.Delete, Entity.AuditTemplateQuestions);
      cannot(AbilityActions.View, Entity.AuditTemplate);
      cannot(AbilityActions.Create, Entity.AuditTemplate);
      cannot(AbilityActions.Edit, Entity.AuditTemplate);
      cannot(AbilityActions.Delete, Entity.AuditTemplate);
      can(AbilityActions.View, Entity.Audit);
      cannot(AbilityActions.Create, Entity.Audit);
      cannot(AbilityActions.Edit, Entity.Audit);
      cannot(AbilityActions.Delete, Entity.Audit);
      can(AbilityActions.AnswerAudit, Entity.Audit);

      can(AbilityActions.View, Entity.Documents);
      can(AbilityActions.Create, Entity.Documents);
      cannot(AbilityActions.Edit, Entity.Documents);
      cannot(AbilityActions.Delete, Entity.Documents);
      cannot(AbilityActions.StartApproval, Entity.Documents);

      cannot(AbilityActions.View, Entity.Electricity);
      cannot(AbilityActions.Create, Entity.Electricity);
      cannot(AbilityActions.Edit, Entity.Electricity);
      cannot(AbilityActions.Delete, Entity.Electricity);

      cannot(AbilityActions.View, Entity.Waste);
      cannot(AbilityActions.Create, Entity.Waste);
      cannot(AbilityActions.Edit, Entity.Waste);
      cannot(AbilityActions.Delete, Entity.Waste);

      can(AbilityActions.View, Entity.Equipment);
      can(AbilityActions.Create, Entity.Equipment);
      can(AbilityActions.Edit, Entity.Equipment);
      can(AbilityActions.Delete, Entity.Equipment);

      can(AbilityActions.View, Entity.EquipmentControl);
      can(AbilityActions.Create, Entity.EquipmentControl);
      can(AbilityActions.Edit, Entity.EquipmentControl);
      can(AbilityActions.Delete, Entity.EquipmentControl);

      can(AbilityActions.View, Entity.ReferenceEquipment);
      can(AbilityActions.Create, Entity.ReferenceEquipment);
      can(AbilityActions.Edit, Entity.ReferenceEquipment);
      can(AbilityActions.Delete, Entity.ReferenceEquipment);

      can(AbilityActions.View, Entity.Notification);
      can(AbilityActions.Edit, Entity.Draft);

      break;
    case 'SuperUserSite':
      can('manage','all');
      can(AbilityActions.View, Entity.Group);
      can(AbilityActions.View, Entity.User);
      cannot(AbilityActions.Create, Entity.Group);
      can(AbilityActions.Create, Entity.User);
      cannot(AbilityActions.Edit, Entity.Group);
      can(AbilityActions.Edit, Entity.User);
      cannot(AbilityActions.Delete, Entity.Group);
      can(AbilityActions.Delete, Entity.User);
      cannot(AbilityActions.View, Entity.Sites);
      cannot(AbilityActions.Create, Entity.Sites);
      cannot(AbilityActions.Edit, Entity.Sites);
      cannot(AbilityActions.Delete, Entity.Sites);

      can(AbilityActions.View, Entity.AccountWithTeams);
      can(AbilityActions.Create, Entity.AccountWithTeams);
      can(AbilityActions.Edit, Entity.AccountWithTeams);
      cannot(AbilityActions.View, Entity.Translations);

      cannot(AbilityActions.Edit, Entity.Workflow);
      break;
    case 'KeyUserSite':
    case 'SystemAdm':
      can('manage','all');
      cannot(AbilityActions.View, Entity.Group);
      cannot(AbilityActions.View, Entity.User);
      cannot(AbilityActions.Create, Entity.Group);
      cannot(AbilityActions.Create, Entity.User);
      cannot(AbilityActions.Edit, Entity.Group);
      cannot(AbilityActions.Edit, Entity.User);
      cannot(AbilityActions.Delete, Entity.Group);
      cannot(AbilityActions.Delete, Entity.User);
      cannot(AbilityActions.View, Entity.Sites);
      cannot(AbilityActions.Create, Entity.Sites);
      cannot(AbilityActions.Edit, Entity.Sites);
      cannot(AbilityActions.Delete, Entity.Sites);

      cannot(AbilityActions.View, Entity.AccountWithTeams);
      cannot(AbilityActions.View, Entity.Translations);

      cannot(AbilityActions.Edit, Entity.Workflow);
      break;
    case 'TenantAdm':
      can('manage','all');
      break;
    default:
      break;
  }
  return new Ability(rules);
}

export default checkAbilities;
