import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {ISelectedSite} from "../../domain/common/interface";

type SiteReducerPayload =
  | ISelectedSite
  | ISelectedSite[]
  | Guid
  | Guid[]
  | null;

export interface ISiteAction
  extends IDispatchBaseActionResult<SiteReducerPayload, any> {}

export interface ISiteState extends IAuditable {
  loading: boolean;
  siteDetails: IAuditableResult<ISelectedSite, any> | null;
  sites: IAuditableResult<ISelectedSite[], any> | null;
  error: any | null;
}

const initialState: ISiteState = {
  loading: false,
  siteDetails: new AuditableResult<ISelectedSite, any>(),
  sites: new AuditableResult<ISelectedSite[], any>(),
  error: null,
};

const siteReducer = (
  state = initialState,
  action: ISiteAction
): ISiteState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_SITES):
      return {
        ...state,
        loading: true,
        sites: {
          data: null,
        },
      };
    case ActionModifier.request(types.GET_SITE):
      return {
        ...state,
        siteDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SAVE_SITE):
      return {
        ...state,
        siteDetails: {
          saving: true,
          data: (state.siteDetails ? state.siteDetails.data : null) as ISelectedSite,
        },
      };
    case ActionModifier.request(types.UPDATE_SITE):
      return {
        ...state,
        siteDetails: {
          saving: true,
          data: (state.siteDetails ? state.siteDetails.data : null) as ISelectedSite,
        },
      };
    case ActionModifier.request(types.DELETE_SITES):
      return {
        ...state,
        sites: {
          loading: true,
          data: (state.sites ? state.sites.data : null) as ISelectedSite[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_SITES):
      return {
        ...state,
        loading: false,
        sites: {
          data: action.payload as ISelectedSite[],
        },
      };
    case ActionModifier.success(types.GET_SITE):
      return {
        ...state,
        siteDetails: {
          loading: false,
          data: action.payload as ISelectedSite,
        },
      };
    case ActionModifier.success(types.SAVE_SITE):
    case ActionModifier.success(types.UPDATE_SITE):
      return {
        ...state,
        siteDetails: {
          saving: false,
          data: (state.siteDetails ? state.siteDetails.data : null) as ISelectedSite,
        },
      };
    case ActionModifier.success(types.DELETE_SITES):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.sites?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        sites: {
          loading: false,
          data: newData as ISelectedSite[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_SITES):
      return {
        ...state,
        loading: false,
        sites: {
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_SITE):
      return {
        ...state,
        siteDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_SITE):
    case ActionModifier.failure(types.UPDATE_SITE):
      return {
        ...state,
        siteDetails: {
          saving: false,
          data: (state.siteDetails ? state.siteDetails.data : null) as ISelectedSite,
        },
      };
    case ActionModifier.failure(types.DELETE_SITES):
      return {
        ...state,
        sites: {
          loading: false,
          data: (state.sites ? state.sites.data : null) as ISelectedSite[],
        },
        error: action.payload as any,
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_SITE):
      return {
        ...state,
        siteDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default siteReducer;
