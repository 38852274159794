import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {WasteItem} from "../../domain/waste/WasteItem";
import {WasteMetadata} from "../../domain/waste/WasteMetadata";

type WasteReducerPayload =
  | WasteItem
  | WasteItem[]
  | WasteMetadata
  | Guid
  | Guid[]
  | null;

export interface IWasteAction
  extends IDispatchBaseActionResult<WasteReducerPayload, any> {}

export interface IWasteState extends IAuditable {
  loading: boolean;
  wasteItems: IAuditableResult<WasteItem[], any> | null;
  wasteItemDetails: IAuditableResult<WasteItem, any> | null;
  wasteMetadata: IAuditableResult<WasteMetadata, any> | null;
  error: any | null;
}

const initialState: IWasteState = {
  loading: false,
  wasteItems: new AuditableResult<WasteItem[], any>(),
  wasteItemDetails: new AuditableResult<WasteItem, any>(),
  wasteMetadata: new AuditableResult<WasteMetadata, any>(),
  error: null,
};

const wasteReducer = (
  state = initialState,
  action: IWasteAction
): IWasteState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_WASTE_ITEMS):
      return {
        ...state,
        wasteItems: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_WASTE_ITEM):
      return {
        ...state,
        wasteItemDetails: {
          loading: true,
          data: null,
        },
      };
      case ActionModifier.request(types.SAVE_WASTE_ITEM):
      case ActionModifier.request(types.UPDATE_WASTE_ITEM):
        return {
          ...state,
          wasteItemDetails: {
            saving: true,
            data: (state.wasteItemDetails ? state.wasteItemDetails.data : null) as WasteItem,
          },
        };
    case ActionModifier.request(types.DELETE_WASTE_ITEM):
      return {
        ...state,
        wasteItems: {
          loading: true,
          data: (state.wasteItems ? state.wasteItems.data : null) as WasteItem[],
        },
      };
    case ActionModifier.request(types.GET_WASTE_METADATA):
      return {
        ...state,
        wasteMetadata: {
          loading: true,
          data: null,
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_WASTE_ITEMS):
      return {
        ...state,
        wasteItems: {
          loading: false,
          data: action.payload as WasteItem[],
        },
      };
    case ActionModifier.success(types.GET_WASTE_ITEM):
      return {
        ...state,
        wasteItemDetails: {
          loading: false,
          data: action.payload as WasteItem,
        },
      };
    case ActionModifier.success(types.SAVE_WASTE_ITEM):
    case ActionModifier.success(types.UPDATE_WASTE_ITEM):
      return {
        ...state,
        wasteItemDetails: {
          saving: false,
          data: (state.wasteItemDetails ? state.wasteItemDetails.data : null) as WasteItem,
        },
      };
    case ActionModifier.success(types.DELETE_WASTE_ITEM):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.wasteItems?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        wasteItems: {
          loading: false,
          data: newData as WasteItem[],
        },
      };
    case ActionModifier.success(types.GET_WASTE_METADATA):
      return {
        ...state,
        wasteMetadata: {
          loading: false,
          data: action.payload as WasteMetadata,
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_WASTE_ITEMS):
      return {
        ...state,
        wasteItems: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_WASTE_ITEM):
      return {
        ...state,
        wasteItemDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_WASTE_ITEM):
    case ActionModifier.failure(types.UPDATE_WASTE_ITEM):
      return {
        ...state,
        wasteItemDetails: {
          saving: false,
          data: (state.wasteItemDetails ? state.wasteItemDetails.data : null) as WasteItem,
        },
      };
    case ActionModifier.failure(types.DELETE_WASTE_ITEM):
      return {
        ...state,
        wasteItems: {
          loading: false,
          data: (state.wasteItems ? state.wasteItems.data : null) as WasteItem[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_WASTE_METADATA):
      return {
        ...state,
        wasteMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };


    // DISPOSE
    case ActionModifier.dispose(types.GET_WASTE_ITEM):
      return {
        ...state,
        wasteItemDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default wasteReducer;
