import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { CommonMetadata } from "domain/complaints/CommonMetadata";
import { IOrganizationalStructure } from "domain/non-conformities/interface";
import { IRelatedItem } from 'domain/related-items/RelatedItem';
import {IFieldDefinition, IGlobalSearchResult, ItemData} from "domain/common/common";
import {Dashboard} from "../../domain/common/dashboard";
import {ISite, IUserMinimal} from "../../domain/common/interface";

type CommonReducerPayload =
| CommonMetadata
| IOrganizationalStructure[]
| IRelatedItem[]
| IFieldDefinition[]
| Dashboard
| IUserMinimal[]
| ISite[]
| IGlobalSearchResult[]
| string[]
| string
| null;

export interface ICommonAction
  extends IDispatchBaseActionResult<CommonReducerPayload, any> {}

export interface ICommonState extends IAuditable {
  loading: boolean;
  title: string;
  searchTerm: string;
  commonMetadata: IAuditableResult<CommonMetadata, any> | null;
  groupUsers: IAuditableResult<IFieldDefinition[], any> | null;
  organizationalStructures: IAuditableResult<IOrganizationalStructure[], any> | null;
  relatedItems: IAuditableResult<IRelatedItem[], any> | null;
  searchResults: IAuditableResult<IFieldDefinition[], any> | null;
  globalSearchResults: IAuditableResult<IGlobalSearchResult[], any> | null;
  dashboard: IAuditableResult<Dashboard, any> | null;
  contacts: ItemData[];
  sites: IAuditableResult<ISite[], any> | null;
  error: any | null;
}

const initialState: ICommonState = {
  loading: false,
  title: "",
  searchTerm: "",
  commonMetadata: new AuditableResult<CommonMetadata, any>(),
  groupUsers: new AuditableResult<IFieldDefinition[], any>(),
  organizationalStructures: new AuditableResult<IOrganizationalStructure[], any>(),
  relatedItems: new AuditableResult<IRelatedItem[], any>(),
  searchResults: new AuditableResult<IFieldDefinition[], any>(),
  globalSearchResults: new AuditableResult<IGlobalSearchResult[], any>(),
  dashboard: new AuditableResult<Dashboard, any>(),
  contacts: [],
  sites: new AuditableResult<ISite[], any>(),
  error: null,
};

const commonReducer = (
  state = initialState,
  action: ICommonAction
): ICommonState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ORGANIZATIONAL_STRUCTURES):
      return {
        ...state,
        organizationalStructures: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMMON_METADATA):
      return {
        ...state,
        commonMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMMON_SITES):
      return {
        ...state,
        sites: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_COMMON_DASHBOARD):
      return {
        ...state,
        dashboard: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_DATA_BY_FIELD_DEFINITION):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_REGULAR_GROUPS):
      return {
        ...state,
        groupUsers: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SEARCH):
      return {
        ...state,
        globalSearchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SEARCH_RELATED_ITEMS):
      return {
        ...state,
        relatedItems: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.UPDATE_RELATED_ITEM):
    case ActionModifier.request(types.SAVE_RELATED_ITEM):
    case ActionModifier.request(types.DELETE_RELATED_ITEMS):
      return {
        ...state,
        relatedItems: {
          loading: true,
          data: (state.relatedItems ? state.relatedItems.data : null) as IRelatedItem[],
        },
      };
    case ActionModifier.request(types.RESTART_WORKFLOW):
      return {
        ...state,
        loading: true
      }

    // SUCCESS
    case ActionModifier.success(types.SET_CUSTOMER_CONTACTS):
      return {
        ...state,
        contacts: (action.payload as IUserMinimal[]).map(contact => ({id: contact.id, label: contact.name})) as ItemData[]
      };
    case ActionModifier.success(types.SET_TITLE):
      return {
        ...state,
        title: action.payload as string
      };
    case ActionModifier.success(types.GET_ORGANIZATIONAL_STRUCTURES):
      return {
        ...state,
        organizationalStructures: {
          loading: false,
          data: action.payload as IOrganizationalStructure[],
        },
      };
    case ActionModifier.success(types.GET_COMMON_METADATA):
      return {
        ...state,
        commonMetadata: {
          loading: false,
          data: action.payload as CommonMetadata,
        },
      };
    case ActionModifier.success(types.GET_COMMON_SITES):
      return {
        ...state,
        sites: {
          loading: false,
          data: action.payload as ISite[],
        },
      };
    case ActionModifier.success(types.GET_COMMON_DASHBOARD):
      return {
        ...state,
        dashboard: {
          loading: false,
          data: action.payload as Dashboard,
        },
      };
    case ActionModifier.success(types.GET_DATA_BY_FIELD_DEFINITION):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as IFieldDefinition[],
        },
        groupUsers: {
          data: [
          ...(state.groupUsers && state.groupUsers?.data ? state.groupUsers.data.filter(group => group.name !== (action.payload as IFieldDefinition[])[0].name) : []),
          (action.payload as IFieldDefinition[])[0]
          ]
        }
      };
    case ActionModifier.success(types.GET_REGULAR_GROUPS):
      return {
        ...state,
        groupUsers: {
          data: action.payload as IFieldDefinition[]
        }
      };
    case ActionModifier.success(types.SEARCH):
      return {
        ...state,
        globalSearchResults: {
          loading: false,
          data: action.payload as IGlobalSearchResult[],
        },
      };
    case ActionModifier.success(types.SET_SEARCH_STRING):
      return {
        ...state,
        searchTerm: action.payload as string,
      };
    case ActionModifier.success(types.SEARCH_RELATED_ITEMS):
      return {
        ...state,
        relatedItems: {
          loading: false,
          data: action.payload as IRelatedItem[],
        },
      };
    case ActionModifier.success(types.SAVE_RELATED_ITEM):
      return {
        ...state,
        relatedItems: {
          loading: false,
          data: (state.relatedItems ? state.relatedItems?.data : null) as IRelatedItem[],
        },
      };
    case ActionModifier.success(types.UPDATE_RELATED_ITEM):
      return {
        ...state,
        relatedItems: {
          loading: false,
          data: state.relatedItems?.data?.map(e => e.id === (action.payload as any).id ? action.payload as any : e) as IRelatedItem[],
        },
      };
    case ActionModifier.success(types.DELETE_RELATED_ITEMS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.relatedItems?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        relatedItems: {
          loading: false,
          data: newData as IRelatedItem[]
        }
      };
    case ActionModifier.success(types.RESTART_WORKFLOW):
      return {
        ...state,
        loading: false
      }

    // FAILURE
    case ActionModifier.failure(types.GET_ORGANIZATIONAL_STRUCTURES):
      return {
        ...state,
        organizationalStructures: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMMON_METADATA):
      return {
        ...state,
        commonMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMMON_SITES):
      return {
        ...state,
        sites: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_COMMON_DASHBOARD):
      return {
        ...state,
        dashboard: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DATA_BY_FIELD_DEFINITION):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_REGULAR_GROUPS):
      return {
        ...state,
        groupUsers: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH):
      return {
        ...state,
        globalSearchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_RELATED_ITEMS):
    case ActionModifier.failure(types.SAVE_RELATED_ITEM):
    case ActionModifier.failure(types.UPDATE_RELATED_ITEM):
    case ActionModifier.failure(types.DELETE_RELATED_ITEMS):
      return {
        ...state,
        relatedItems: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.RESTART_WORKFLOW):
      return {
        ...state,
        loading: false
      }

    //DISPOSE
    case ActionModifier.dispose(types.SEARCH_RELATED_ITEMS):
      return {
        ...state,
        relatedItems: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default commonReducer;
