export enum AbilityActions {
  View = "View",
  Edit = "Edit",
  Delete = "Delete",
  Create = "Create",
  CreateInTask = "Create in task",
  EditInTask = "Edit in task",
  DeleteInTask = "Delete in task",
  AnswerAudit = "Answer audit",
  StartApproval = "Start approval"
}
