import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import {IWorkflowTask} from "../../domain/workflow-instances/IWorkflowTask";
import {IWorkflowTaskExtended} from "../../domain/workflow-instances/IWorkflowTaskExtended";

type TaskReducerPayload =
  | IWorkflowTask[]
  | IWorkflowTaskExtended
  | null;

export interface ITaskAction
  extends IDispatchBaseActionResult<TaskReducerPayload, any> {}

export interface ITaskState extends IAuditable {
  loading: boolean;
  updating: boolean;
  myTasks: IAuditableResult<IWorkflowTask[], any> | null;
  dashboardActiveTasks: IAuditableResult<IWorkflowTask[], any> | null;
  dashboardOverdueTasks: IAuditableResult<IWorkflowTask[], any> | null;
  task: IAuditableResult<IWorkflowTaskExtended, any> | null;
  error: any | null;
}

const initialState: ITaskState = {
  loading: false,
  updating: false,
  myTasks: new AuditableResult<IWorkflowTask[], any>(),
  dashboardActiveTasks: new AuditableResult<IWorkflowTask[], any>(),
  dashboardOverdueTasks: new AuditableResult<IWorkflowTask[], any>(),
  task: new AuditableResult<IWorkflowTaskExtended, any>(),
  error: null,
};

const taskReducer = (
  state = initialState,
  action: ITaskAction
): ITaskState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_MY_TASKS):
      return {
        ...state,
        loading: true,
        myTasks: {
          loading: true,
          data: action.loadMore ? state.myTasks!.data : null,
        },
      };
    case ActionModifier.request(types.GET_DASHBOARD_ACTIVE_TASKS):
      return {
        ...state,
        loading: true,
        dashboardActiveTasks: {
          data: null,
        },
      };
    case ActionModifier.request(types.GET_DASHBOARD_OVERDUE_TASKS):
      return {
        ...state,
        loading: true,
        dashboardOverdueTasks: {
          data: null,
        },
      };
    case ActionModifier.request(types.GET_TASK):
      return {
        ...state,
        loading: true,
        task: {
          data: null,
        },
      };
    case ActionModifier.request(types.UPDATE_TASK):
    case ActionModifier.request(types.PATCH_TASK):
      return {
        ...state,
        loading: true,
      };
    case ActionModifier.request(types.DELEGATE_TASKS):
      return {
        ...state,
        updating: true,
      };

    // SUCCESS
    case ActionModifier.success(types.GET_MY_TASKS):
      return {
        ...state,
        loading: false,
        myTasks: {
          loading: false,
          data: action.loadMore ? [...state.myTasks!.data, ...(action.payload as IWorkflowTask[])] : action.payload as IWorkflowTask[],
        },
      };
    case ActionModifier.success(types.GET_DASHBOARD_ACTIVE_TASKS):
      return {
        ...state,
        loading: false,
        dashboardActiveTasks: {
          data: action.loadMore ? [...state.dashboardActiveTasks!.data, ...(action.payload as IWorkflowTask[])] : action.payload as IWorkflowTask[],
        },
      };
    case ActionModifier.success(types.GET_DASHBOARD_OVERDUE_TASKS):
      return {
        ...state,
        loading: false,
        dashboardOverdueTasks: {
          data: action.loadMore ? [...state.dashboardOverdueTasks!.data, ...(action.payload as IWorkflowTask[])] : action.payload as IWorkflowTask[],
        },
      };
    case ActionModifier.success(types.GET_TASK):
      return {
        ...state,
        loading: false,
        task: {
          data: action.payload as IWorkflowTaskExtended,
        },
      };
    case ActionModifier.success(types.UPDATE_TASK):
    case ActionModifier.success(types.PATCH_TASK):
      return {
        ...state,
        loading: false
      }
    case ActionModifier.success(types.DELEGATE_TASKS):
      return {
        ...state,
        updating: false
      }

    // FAILURE
    case ActionModifier.failure(types.GET_MY_TASKS):
      return {
        ...state,
        loading: false,
        myTasks: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DASHBOARD_ACTIVE_TASKS):
      return {
        ...state,
        loading: false,
        dashboardActiveTasks: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_DASHBOARD_OVERDUE_TASKS):
      return {
        ...state,
        loading: false,
        dashboardOverdueTasks: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_TASK):
      return {
        ...state,
        loading: false,
        task: {
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.UPDATE_TASK):
    case ActionModifier.failure(types.PATCH_TASK):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      }
    case ActionModifier.failure(types.DELEGATE_TASKS):
      return {
        ...state,
        updating: false,
        error: action.payload as any,
      }

    //DISPOSE
    case ActionModifier.dispose(types.GET_MY_TASKS):
      return {
        ...state,
        loading: false,
        myTasks: {
          data: [],
          error: null,
        },
      };
    case ActionModifier.dispose(types.GET_DASHBOARD_ACTIVE_TASKS):
      return {
        ...state,
        loading: false,
        dashboardActiveTasks: {
          data: [],
          error: null,
        },
      };
    case ActionModifier.dispose(types.GET_DASHBOARD_OVERDUE_TASKS):
      return {
        ...state,
        loading: false,
        dashboardOverdueTasks: {
          data: [],
          error: null,
        },
      };
    default:
      return state;
  }
};

export default taskReducer;
