import IDispatchBaseActionResult from "redux/entities/IDispatchBaseActionResults";
import * as types from "../actions/actionTypes";
import IAuditableResult from "redux/entities/IAuditableResult";
import IAuditable from "redux/entities/IAuditable";
import AuditableResult from "redux/entities/AuditableResult";
import { ActionModifier } from "redux/action-creators/ActionModifier";
import { IAttachments } from "domain/attachments/Attachment";
import { IAttachmentToken } from "domain/attachments/IAttachmentToken";
import {IComplaintEvent} from "../../domain/complaint-events/ComplaintEvent";
import {Equipment} from "../../domain/equipments/Equipment";
import {EquipmentControl} from "../../domain/equipments/EquipmentControl";
import {EquipmentMetadata} from "../../domain/equipments/EquipmentMetadata";
import {EquipmentControlMetadata} from "../../domain/equipments/EquipmentControlMetadata";
import {ReferenceEquipment} from "../../domain/equipments/ReferenceEquipment";

type EquipmentReducerPayload =
  | Equipment
  | EquipmentControl
  | ReferenceEquipment
  | Equipment[]
  | EquipmentControl[]
  | ReferenceEquipment[]
  | EquipmentMetadata
  | EquipmentControlMetadata
  | Guid
  | Guid[]
  | IAttachments
  | IAttachmentToken
  | IAttachments[]
  | IComplaintEvent[]
  | null;

export interface IEquipmentAction
  extends IDispatchBaseActionResult<EquipmentReducerPayload, any> {}

export interface IEquipmentState extends IAuditable {
  loading: boolean;
  equipmentDetails: IAuditableResult<Equipment, any> | null;
  equipmentControlDetails: IAuditableResult<EquipmentControl, any> | null;
  referenceEquipmentDetails: IAuditableResult<ReferenceEquipment, any> | null;
  equipments: IAuditableResult<Equipment[], any> | null;
  equipmentControls: IAuditableResult<EquipmentControl[], any> | null;
  referenceEquipments: IAuditableResult<ReferenceEquipment[], any> | null;
  searchResults: IAuditableResult<Equipment[], any> | null;
  equipmentsMetadata: IAuditableResult<EquipmentMetadata, any> | null;
  equipmentControlsMetadata: IAuditableResult<EquipmentControlMetadata, any> | null;
  attachments: IAuditableResult<IAttachments[], any> | null;
  referenceAttachments: IAuditableResult<IAttachments[], any> | null;
  attachmentToken: IAuditableResult<IAttachmentToken, any> | null;
  events: IAuditableResult<IComplaintEvent[], any> | null;
  referenceEvents: IAuditableResult<IComplaintEvent[], any> | null;
  error: any | null;
}

const initialState: IEquipmentState = {
  loading: false,
  equipmentDetails: new AuditableResult<Equipment, any>(),
  equipmentControlDetails: new AuditableResult<EquipmentControl, any>(),
  referenceEquipmentDetails: new AuditableResult<ReferenceEquipment, any>(),
  equipments: new AuditableResult<Equipment[], any>(),
  equipmentControls: new AuditableResult<EquipmentControl[], any>(),
  referenceEquipments: new AuditableResult<ReferenceEquipment[], any>(),
  searchResults: new AuditableResult<Equipment[], any>(),
  equipmentsMetadata: new AuditableResult<EquipmentMetadata, any>(),
  equipmentControlsMetadata: new AuditableResult<EquipmentControlMetadata, any>(),
  attachments: new AuditableResult<IAttachments[], any>(),
  referenceAttachments: new AuditableResult<IAttachments[], any>(),
  attachmentToken: new AuditableResult<IAttachmentToken, any>(),
  events: new AuditableResult<IComplaintEvent[], any>(),
  referenceEvents: new AuditableResult<IComplaintEvent[], any>(),
  error: null,
};

const equipmentReducer = (
  state = initialState,
  action: IEquipmentAction
): IEquipmentState => {
  switch (action.type) {
    // REQUEST
    case ActionModifier.request(types.GET_ALL_EQUIPMENTS):
      return {
        ...state,
        equipments: {
          loading: true,
          data: action.loadMore ? state.equipments!.data : null,
        },
      };
    case ActionModifier.request(types.GET_ALL_EQUIPMENT_CONTROLS):
      return {
        ...state,
        equipmentControls: {
          loading: true,
          data: action.loadMore ? state.equipmentControls!.data : null,
        },
      };
    case ActionModifier.request(types.GET_ALL_REFERENCE_EQUIPMENTS):
      return {
        ...state,
        referenceEquipments: {
          loading: true,
          data: action.loadMore ? state.referenceEquipments!.data : null,
        },
      };
    case ActionModifier.request(types.SEARCH_EQUIPMENTS):
      return {
        ...state,
        searchResults: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENTS_METADATA):
      return {
        ...state,
        equipmentsMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENT_CONTROLS_METADATA):
      return {
        ...state,
        equipmentControlsMetadata: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControls: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.SAVE_EQUIPMENT):
    case ActionModifier.request(types.UPDATE_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          saving: true,
          data: (state.equipmentDetails ? state.equipmentDetails.data : null) as Equipment,
        },
      };
    case ActionModifier.request(types.SAVE_EQUIPMENT_CONTROL):
    case ActionModifier.request(types.UPDATE_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControlDetails: {
          saving: true,
          data: (state.equipmentControlDetails ? state.equipmentControlDetails.data : null) as EquipmentControl,
        },
      };
    case ActionModifier.request(types.SAVE_REFERENCE_EQUIPMENT):
    case ActionModifier.request(types.UPDATE_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          saving: true,
          data: (state.referenceEquipmentDetails ? state.referenceEquipmentDetails.data : null) as ReferenceEquipment,
        },
      };
    case ActionModifier.request(types.SAVE_EQUIPMENT_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_EQUIPMENT_ATTACHMENT):
    case ActionModifier.request(types.DELETE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: true,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
      };
    case ActionModifier.request(types.SAVE_REFERENCE_EQUIPMENT_ATTACHMENT):
    case ActionModifier.request(types.UPDATE_REFERENCE_EQUIPMENT_ATTACHMENT):
    case ActionModifier.request(types.DELETE_REFERENCE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        referenceAttachments: {
          loading: true,
          data: (state.referenceAttachments ? state.referenceAttachments.data : null) as IAttachments[],
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_REFERENCE_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        referenceAttachments: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_EQUIPMENT_EVENTS):
      return {
        ...state,
        events: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.GET_REFERENCE_EQUIPMENT_EVENTS):
      return {
        ...state,
        referenceEvents: {
          loading: true,
          data: null,
        },
      };
    case ActionModifier.request(types.DELETE_EQUIPMENTS):
      return {
        ...state,
        equipments: {
          loading: true,
          data: (state.equipments ? state.equipments.data : null) as Equipment[],
        },
      };
    case ActionModifier.request(types.DELETE_EQUIPMENT_CONTROLS):
      return {
        ...state,
        equipmentControls: {
          loading: true,
          data: (state.equipmentControls ? state.equipmentControls.data : null) as EquipmentControl[],
        },
      };
    case ActionModifier.request(types.DELETE_REFERENCE_EQUIPMENTS):
      return {
        ...state,
        referenceEquipments: {
          loading: true,
          data: (state.referenceEquipments ? state.referenceEquipments.data : null) as ReferenceEquipment[],
        },
      };

    // SUCCESS
    case ActionModifier.success(types.GET_ALL_EQUIPMENTS):
      return {
        ...state,
        equipments: {
          loading: false,
          data: action.loadMore ? [...state.equipments!.data, ...(action.payload as Equipment[])] : action.payload as Equipment[],
        },
      };
    case ActionModifier.success(types.GET_ALL_EQUIPMENT_CONTROLS):
      return {
        ...state,
        equipmentControls: {
          loading: false,
          data: action.loadMore ? [...state.equipmentControls!.data, ...(action.payload as EquipmentControl[])] : action.payload as EquipmentControl[],
        },
      };
    case ActionModifier.success(types.GET_ALL_REFERENCE_EQUIPMENTS):
      return {
        ...state,
        referenceEquipments: {
          loading: false,
          data: action.loadMore ? [...state.referenceEquipments!.data, ...(action.payload as ReferenceEquipment[])] : action.payload as ReferenceEquipment[],
        },
      };
    case ActionModifier.success(types.SEARCH_EQUIPMENTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: action.payload as Equipment[],
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENTS_METADATA):
      return {
        ...state,
        equipmentsMetadata: {
          loading: false,
          data: action.payload as EquipmentMetadata,
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENT_CONTROLS_METADATA):
      return {
        ...state,
        equipmentControlsMetadata: {
          loading: false,
          data: action.payload as EquipmentControlMetadata,
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          loading: false,
          data: action.payload as Equipment,
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControls: {
          loading: false,
          data: action.payload as EquipmentControl[],
        },
      };
    case ActionModifier.success(types.GET_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          loading: false,
          data: action.payload as ReferenceEquipment,
        },
      };
    case ActionModifier.success(types.SAVE_EQUIPMENT):
    case ActionModifier.success(types.UPDATE_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          saving: false,
          data: (state.equipmentDetails ? state.equipmentDetails.data : null) as Equipment,
        },
      };
    case ActionModifier.success(types.SAVE_EQUIPMENT_CONTROL):
    case ActionModifier.success(types.UPDATE_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControlDetails: {
          saving: false,
          data: (state.equipmentControlDetails ? state.equipmentControlDetails.data : null) as EquipmentControl,
        },
      };
    case ActionModifier.success(types.SAVE_REFERENCE_EQUIPMENT):
    case ActionModifier.success(types.UPDATE_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          saving: false,
          data: (state.referenceEquipmentDetails ? state.referenceEquipmentDetails.data : null) as ReferenceEquipment,
        },
      };
    case ActionModifier.success(types.DELETE_EQUIPMENTS):
      const ids: Guid[] = action.payload as Guid[];
      const newData = state.equipments?.data?.filter(
        (e) => !ids.includes(e.id as any)
      );
      return {
        ...state,
        equipments: {
          loading: false,
          data: newData as Equipment[],
        },
      };
    case ActionModifier.success(types.DELETE_EQUIPMENT_CONTROLS):
      const equipmentControlsIds: Guid[] = action.payload as Guid[];
      const newEquipmentControlsData = state.equipmentControls?.data?.filter(
        (e) => !equipmentControlsIds.includes(e.id as any)
      );
      return {
        ...state,
        equipmentControls: {
          loading: false,
          data: newEquipmentControlsData as EquipmentControl[],
        },
      };
    case ActionModifier.success(types.DELETE_REFERENCE_EQUIPMENTS):
      const referenceEquipmentIds: Guid[] = action.payload as Guid[];
      const newReferenceEquipmentData = state.referenceEquipments?.data?.filter(
        (e) => !referenceEquipmentIds.includes(e.id as any)
      );
      return {
        ...state,
        referenceEquipments: {
          loading: false,
          data: newReferenceEquipmentData as ReferenceEquipment[],
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          loading: false,
          data: action.payload as IAttachments[],
        },
      };
    case ActionModifier.success(types.GET_REFERENCE_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        referenceAttachments: {
          loading: false,
          data: action.payload as IAttachments[],
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (complaintEvent1, complaintEvent2) =>
              complaintEvent2.eventTime.localeCompare(complaintEvent1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_REFERENCE_EQUIPMENT_EVENTS):
      return {
        ...state,
        referenceEvents: {
          loading: false,
          data: (action.payload as IComplaintEvent[]).sort(
            (complaintEvent1, complaintEvent2) =>
              complaintEvent2.eventTime.localeCompare(complaintEvent1.eventTime)),
        },
      };
    case ActionModifier.success(types.GET_EQUIPMENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          loading: false,
          data: action.payload as IAttachmentToken,
        },
      };
    case ActionModifier.success(types.DELETE_EQUIPMENT_ATTACHMENT):
      const idEquipmentAttachment: Guid = action.payload as any;
      return {
        ...state,
        loading: false,
        attachments: {
          loading: false,
          data: state.attachments?.data?.filter((e) => e.id !== idEquipmentAttachment) as IAttachments[]
        },
      };
    case ActionModifier.success(types.DELETE_REFERENCE_EQUIPMENT_ATTACHMENT):
      const idReferenceAttachment: Guid = action.payload as any;
      return {
        ...state,
        loading: false,
        referenceAttachments: {
          loading: false,
          data: state.referenceAttachments?.data?.filter((e) => e.id !== idReferenceAttachment) as IAttachments[]
        },
      };
    case ActionModifier.success(types.SAVE_EQUIPMENT_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
      };
    case ActionModifier.success(types.SAVE_REFERENCE_EQUIPMENT_ATTACHMENT):
    case ActionModifier.success(types.UPDATE_REFERENCE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        referenceAttachments: {
          loading: false,
          data: (state.referenceAttachments ? state.referenceAttachments.data : null) as IAttachments[],
        },
      };

    // FAILURE
    case ActionModifier.failure(types.GET_ALL_EQUIPMENTS):
      return {
        ...state,
        equipments: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ALL_EQUIPMENT_CONTROLS):
      return {
        ...state,
        equipmentControls: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_ALL_REFERENCE_EQUIPMENTS):
      return {
        ...state,
        referenceEquipments: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SEARCH_EQUIPMENTS):
      return {
        ...state,
        searchResults: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EQUIPMENTS_METADATA):
      return {
        ...state,
        equipmentsMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EQUIPMENT_CONTROLS_METADATA):
      return {
        ...state,
        equipmentControlsMetadata: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControls: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          loading: false,
          data: null,
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.SAVE_EQUIPMENT):
    case ActionModifier.failure(types.UPDATE_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          saving: false,
          data: (state.equipmentDetails ? state.equipmentDetails.data : null) as Equipment,
        },
      };
    case ActionModifier.failure(types.SAVE_EQUIPMENT_CONTROL):
    case ActionModifier.failure(types.UPDATE_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControlDetails: {
          saving: false,
          data: (state.equipmentControlDetails ? state.equipmentControlDetails.data : null) as EquipmentControl,
        },
      };
    case ActionModifier.failure(types.SAVE_REFERENCE_EQUIPMENT):
    case ActionModifier.failure(types.UPDATE_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          saving: false,
          data: (state.referenceEquipmentDetails ? state.referenceEquipmentDetails.data : null) as ReferenceEquipment,
        },
      };
    case ActionModifier.failure(types.DELETE_EQUIPMENTS):
      return {
        ...state,
        equipments: {
          loading: false,
          data: (state.equipments ? state.equipments.data : null) as Equipment[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_EQUIPMENT_CONTROLS):
      return {
        ...state,
        equipmentControls: {
          loading: false,
          data: (state.equipmentControls ? state.equipmentControls.data : null) as EquipmentControl[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_REFERENCE_EQUIPMENTS):
      return {
        ...state,
        referenceEquipments: {
          loading: false,
          data: (state.referenceEquipments ? state.referenceEquipments.data : null) as ReferenceEquipment[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.GET_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_REFERENCE_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        referenceAttachments: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EQUIPMENT_EVENTS):
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_REFERENCE_EQUIPMENT_EVENTS):
      return {
        ...state,
        referenceEvents: {
          loading: false,
          data: [],
          error: action.payload as any,
        },
      };
    case ActionModifier.failure(types.GET_EQUIPMENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        loading: false,
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.SAVE_REFERENCE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        referenceAttachments: {
          loading: false,
          data: (state.referenceAttachments ? state.referenceAttachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.UPDATE_REFERENCE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        referenceAttachments: {
          loading: false,
          data: (state.referenceAttachments ? state.referenceAttachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        attachments: {
          loading: false,
          data: (state.attachments ? state.attachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };
    case ActionModifier.failure(types.DELETE_REFERENCE_EQUIPMENT_ATTACHMENT):
      return {
        ...state,
        referenceAttachments: {
          loading: false,
          data: (state.referenceAttachments ? state.referenceAttachments.data : null) as IAttachments[],
        },
        error: action.payload as any,
      };

    // DISPOSE
    case ActionModifier.dispose(types.GET_EQUIPMENT):
      return {
        ...state,
        equipmentDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_EQUIPMENT_CONTROL):
      return {
        ...state,
        equipmentControlDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_REFERENCE_EQUIPMENT):
      return {
        ...state,
        referenceEquipmentDetails: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        attachments: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_REFERENCE_EQUIPMENT_ATTACHMENTS):
      return {
        ...state,
        referenceAttachments: {
          data: null,
          error: null,
          loading: false,
        },
      };
    case ActionModifier.dispose(types.GET_EQUIPMENT_ATTACHMENT_TOKEN):
      return {
        ...state,
        attachmentToken: {
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default equipmentReducer;
